import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { selectAuth } from '../auth/auth.selectors';
import { catchError, map, mergeMap, skipWhile, take } from 'rxjs/operators';
import { AuthState } from '../auth/auth.models';
import { forkJoin, Observable, of } from 'rxjs';
import { enterZone } from '@core/operator/enterZone';
import { Authority } from '@shared/models/authority.enum';
import { DialogService } from '@core/services/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '@core/services/utils.service';
import { isObject } from '@core/utils';
import { ReturnStatement } from '@angular/compiler';
import { AlertPromise } from 'selenium-webdriver';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private store: Store<AppState>,
              private router: Router,
              private authService: AuthService,
              private dialogService: DialogService,
              private utils: UtilsService,
              private translate: TranslateService,
              private zone: NgZone) {}

  getAuthState(): Observable<AuthState> {
    return this.store.pipe(
      select(selectAuth),
      skipWhile((authState) => !authState || !authState.isUserLoaded),
      take(1),
      enterZone(this.zone)
    );
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {

    return this.getAuthState().pipe(
      mergeMap((authState) => {
        /**
         * 注意！注意！ 注意！ 此处对于partI和partII 合并部署，路由运行逻辑至关重要。
         * mark: 判定 partII 是否与 partI 一起部署，设置值在system_config.js文件里面
         * urlMark: 判断当前路由跳转是否是 去 login登录页面
         * (window as any).partI： 判断是否是从partI系统加载 partII 系统 ,赋值是在 index.html文件里面
         * (window as any).logoutPartII： 判断是否是在 partI 系统点击了退出登录, 赋值是在 index.html文件里面
         * authState.authUser.authority： 判断当前用户是否需要跳转到partI 系统
        */
        // 跳转到partI首页
        let mark:boolean = `${(window as any).isMergeDeploy}`== 'true' ? true : false;
        let urlMark:boolean = state.url.indexOf("login") > -1 ? true : false;
        console.log("是否是嵌入式：",(window as any).partI)
        if(authState.authUser && mark && !urlMark && !(window as any).logoutPartII) {
          // 是否是通过accessToken和refreshToken校验直接进入，例如：
          // http://localhost:7708/?accessToken=xxx&refreshToken=xxx&fromPartI=1&redictUrlTo=infra
          if (!(window as any).partI || ((window as any).partI && (window as any).redictUrlTo)) {
            let authoritys = authState.authUser.authority
            // isAdminAccount字段用户判断是admin/system账号还是普通用户登录
            if (location.href.indexOf('publicId') < 0) {
              if (authoritys == "TENANT_ADMIN" || authoritys == "SYS_ADMIN") {
                localStorage.setItem('isAdminAccount', 'true')
              } else if (authoritys == "CUSTOMER_USER") {
                localStorage.setItem('isAdminAccount', 'false')
              }
            }
            const jwt_token = localStorage.getItem('jwt_token')
            localStorage.setItem('token', jwt_token)
            if (location.href.indexOf('publicId') < 0) {
              if (authoritys == "TENANT_ADMIN" || authoritys == "CUSTOMER_USER") {
                if ((window as any).isClient) {
                  window.open(`${(window as any).partiUrl}/#/map-map?modelname='map'`, '_self')
                } else {
                  window.open(`${(window as any).partiUrl}`, '_self')
                }
                return
              }
            }
          }
        }

        const url: string = state.url;
        let lastChild = state.root;
        const urlSegments: string[] = [];
        if (lastChild.url) {
          urlSegments.push(...lastChild.url.map(segment => segment.path));
        }
        while (lastChild.children.length) {
          lastChild = lastChild.children[0];
          if (lastChild.url) {
            urlSegments.push(...lastChild.url.map(segment => segment.path));
          }
        }
        const path = urlSegments.join('.');
        const publicId = this.utils.getQueryParam('publicId');
        const data = lastChild.data || {};
        const params = lastChild.params || {};
        const isPublic = data.module === 'public';

        if (!authState.isAuthenticated) {
          if (publicId && publicId.length > 0) {
            this.authService.setUserFromJwtToken(null, null, false);
            this.authService.reloadUser();
            return of(false);
          } else if (!isPublic) {
            this.authService.redirectUrl = url;
            // this.authService.gotoDefaultPlace(false);
            return of(this.authService.defaultUrl(false));
          } else {
            if (path === 'login') {
              return forkJoin([this.authService.loadOAuth2Clients()]).pipe(
                map(() => {
                  return true;
                })
              );
            } else {
              return of(true);
            }
          }
        } else {
          if (authState.authUser.isPublic) {
            if (this.authService.parsePublicId() !== publicId) {
              if (publicId && publicId.length > 0) {
                this.authService.setUserFromJwtToken(null, null, false);
                this.authService.reloadUser();
              } else {
                this.authService.logout();
              }
              return of(false);
            }
          }
          const defaultUrl = this.authService.defaultUrl(true, authState, path, params);
          if (defaultUrl) {
            // this.authService.gotoDefaultPlace(true);
            return of(defaultUrl);
          } else {
            const authority = Authority[authState.authUser.authority];
            if (data.auth && data.auth.indexOf(authority) === -1) {
              this.dialogService.forbidden();
              return of(false);
            } else if (data.redirectTo) {
              let redirect;
              if (isObject(data.redirectTo)) {
                redirect = data.redirectTo[authority];
              } else {
                redirect = data.redirectTo;
              }
              return of(this.router.parseUrl(redirect));
            } else {
              return of(true);
            }
          }
        }
      }),
      catchError((err => { console.error(err); return of(false); } ))
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
