import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

import { environment as env } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(
    private translate: TranslateService,
    private title: Title,
    private sanitizer: DomSanitizer
  ) {}

  setTitle(
    snapshot: ActivatedRouteSnapshot,
    lazyTranslate?: TranslateService
  ) {
    let lastChild = snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const { title } = lastChild.data;
    const translate = lazyTranslate || this.translate;
    if (title) {
      translate
        .get(title)
        .pipe(filter(translatedTitle => translatedTitle !== title))
        .subscribe(translatedTitle =>{
            // 设置网站标题
            if (localStorage.getItem("plat_title")) {
              this.title.setTitle(localStorage.getItem("plat_title") + ` | ${translatedTitle}`)
            } else {
              this.title.setTitle(`${env.appTitle} | ${translatedTitle}`)
            }
          }
        );
    } else {
      // 设置网站标题
      if (localStorage.getItem("plat_title")) {
        this.title.setTitle(localStorage.getItem("plat_title"));
      } else {
        this.title.setTitle(env.appTitle);
      }
    }
  }
}
