<form [formGroup]="jsonFormGroup" (ngSubmit)="add()" style="min-width: 400px;">
  <mat-toolbar fxLayout="row" color="primary">
    <h2>{{ title }}</h2>
    <span fxFlex></span>
    <button mat-button mat-icon-button
            (click)="cancel()"
            type="button">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
  </mat-progress-bar>
  <div style="height: 4px;" *ngIf="!(isLoading$ | async)"></div>
  <div mat-dialog-content>
    <fieldset [disabled]="isLoading$ | async">
      <tb-json-object-edit
        formControlName="json"
        label="{{ 'value.json-value' | translate }}"
        validateContent="true"
        [required]="true"
        [fillHeight]="false">
      </tb-json-object-edit>
    </fieldset>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <span fxFlex></span>
    <button mat-button color="primary"
            type="button"
            [disabled]="(isLoading$ | async)"
            (click)="cancel()" cdkFocusInitial>
      {{ 'action.cancel' | translate }}
    </button>
    <button mat-button mat-raised-button color="primary"
            type="submit"
            [disabled]="(isLoading$ | async) || jsonFormGroup.invalid || !jsonFormGroup.dirty">
      {{ 'action.save' | translate }}
    </button>
  </div>
</form>
