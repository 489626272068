<div style="background: #fff;" [ngClass]="{'fill-height': fillHeight}"
     tb-fullscreen
     [fullscreen]="fullscreen" (fullscreenChanged)="onFullscreen()" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" style="height: 40px;" class="tb-json-content-toolbar">
    <label class="tb-title no-padding" [ngClass]="{'tb-error': !contentValid}">{{ label }}</label>
    <span fxFlex></span>
    <button type="button"
            mat-button *ngIf="!readonly && !disabled" class="tidy" (click)="beautifyJSON()">
      {{'js-func.tidy' | translate }}
    </button>
    <button type="button"
            mat-button *ngIf="!readonly && !disabled" class="tidy" (click)="minifyJSON()">
      {{'js-func.mini' | translate }}
    </button>
    <fieldset style="width: initial">
      <div matTooltip="{{(fullscreen ? 'fullscreen.exit' : 'fullscreen.expand') | translate}}"
           matTooltipPosition="above"
           style="border-radius: 50%"
           (click)="fullscreen = !fullscreen">
        <button type='button' mat-button mat-icon-button class="tb-mat-32">
          <mat-icon class="material-icons">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
        </button>
      </div>
    </fieldset>
  </div>
  <div id="tb-json-panel" tb-toast toastTarget="{{toastTargetId}}"
       class="tb-json-content-panel" fxLayout="column">
    <div #jsonEditor id="tb-json-input" [ngStyle]="editorStyle" [ngClass]="{'fill-height': fillHeight}"></div>
  </div>
</div>
