<div style="background: #fff;" [ngClass]="{'fill-height': fillHeight}"
     tb-fullscreen
     [fullscreen]="fullscreen" (fullscreenChanged)="onFullscreen()" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" class="tb-json-object-toolbar">
    <label class="tb-title no-padding"
           [ngClass]="{'tb-required': required,
                       'tb-readonly': readonly,
                       'tb-error': !objectValid}">{{ label }}</label>
    <span fxFlex></span>
    <button type="button"
            mat-button *ngIf="!readonly && !disabled" class="tidy" (click)="beautifyJSON()">
      {{'js-func.tidy' | translate }}
    </button>
    <button type="button"
            mat-button *ngIf="!readonly && !disabled" class="tidy" (click)="minifyJSON()">
      {{'js-func.mini' | translate }}
    </button>
    <button mat-button mat-icon-button (click)="fullscreen = !fullscreen"
            matTooltip="{{(fullscreen ? 'fullscreen.exit' : 'fullscreen.expand') | translate}}"
            matTooltipPosition="above">
      <mat-icon class="material-icons">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
    </button>
  </div>
  <div id="tb-json-panel" tb-toast toastTarget="{{toastTargetId}}"
       class="tb-json-object-panel" fxLayout="column">
    <div #jsonEditor id="tb-json-input" [ngStyle]="editorStyle" [ngClass]="{'fill-height': fillHeight}"></div>
  </div>
</div>
