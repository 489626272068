<div class="tb-reset-password-content mat-app-background tb-dark" fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
  <mat-card fxFlex="initial" class="tb-reset-password-card ui-resetpwd-box">
    <mat-card-title class="layout-padding tb-card-title">
      <span translate class="mat-headline tb-card-title">login.password-reset</span>
    </mat-card-title>
    <mat-card-subtitle *ngIf="isExpiredPassword" class="layout-padding tb-card-title">
      <div class="tb-card-title" translate>login.expired-password-reset-message</div>
    </mat-card-subtitle>
    <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
    </mat-progress-bar>
    <span style="height: 4px;" *ngIf="!(isLoading$ | async)"></span>
    <mat-card-content>
      <form [formGroup]="resetPassword" (ngSubmit)="onResetPassword()">
        <fieldset [disabled]="isLoading$ | async">
          <div tb-toast fxLayout="column" class="layout-padding">
            <!--<span style="height: 50px;"></span>-->
            <mat-form-field class="mat-block">
              <mat-label translate>login.new-password</mat-label>
              <input matInput type="password" autofocus formControlName="newPassword"/>
              <mat-icon class="material-icons" matPrefix>lock</mat-icon>
            </mat-form-field>
            <mat-form-field class="mat-block">
              <mat-label translate>login.new-password-again</mat-label>
              <input matInput type="password" formControlName="newPassword2"/>
              <mat-icon class="material-icons" matPrefix>lock</mat-icon>
            </mat-form-field>
            <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="16px" fxLayoutAlign="start center"
                 fxLayoutAlign.gt-sm="center start">
              <button mat-raised-button color="primary" type="button" [disabled]="(isLoading$ | async)"
                      routerLink="/login">
                {{ 'action.cancel' | translate }}
              </button>
              <button mat-raised-button color="accent" type="submit" [disabled]="(isLoading$ | async)">
                {{ 'login.reset-password' | translate }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </mat-card-content>
  </mat-card>
</div>
