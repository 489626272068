import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../core.state';
import { selectAuthUser, selectIsAuthenticated } from '../auth/auth.selectors';
import { take } from 'rxjs/operators';
import { HomeSection, MenuSection } from '@core/services/menu.models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Authority } from '@shared/models/authority.enum';
import { AuthUser } from '@shared/models/user.model';
import { guid } from '@core/utils';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menuSections$: Subject<Array<MenuSection>> = new BehaviorSubject<Array<MenuSection>>([]);
  homeSections$: Subject<Array<HomeSection>> = new BehaviorSubject<Array<HomeSection>>([]);

  constructor(private store: Store<AppState>, private authService: AuthService) {
    this.store.pipe(select(selectIsAuthenticated)).subscribe(
      (authenticated: boolean) => {
        if (authenticated) {
          this.buildMenu();
        }
      }
    );
  }

  private buildMenu() {
    this.store.pipe(select(selectAuthUser), take(1)).subscribe(
      (authUser: AuthUser) => {
        if (authUser) {
          let menuSections: Array<MenuSection>;
          let homeSections: Array<HomeSection>;
          switch (authUser.authority) {
            case Authority.SYS_ADMIN:
              menuSections = this.buildSysAdminMenu(authUser);
              homeSections = this.buildSysAdminHome(authUser);
              break;
            case Authority.TENANT_ADMIN:
              menuSections = this.buildTenantAdminMenu(authUser);
              homeSections = this.buildTenantAdminHome(authUser);
              break;
            case Authority.CUSTOMER_USER:
              menuSections = this.buildCustomerUserMenu(authUser);
              homeSections = this.buildCustomerUserHome(authUser);
              break;
          }
          this.menuSections$.next(menuSections);
          this.homeSections$.next(homeSections);
        }
      }
    );
  }

  private buildSysAdminMenu(authUser: any): Array<MenuSection> {
    const sections: Array<MenuSection> = [];
    sections.push(
      {
        id: guid(),
        name: 'home.home',
        type: 'link',
        path: '/home',
        icon: '&#xe60a;'
      },
      {
        id: guid(),
        name: 'tenant.tenants',
        type: 'link',
        path: '/admins',
        icon: '&#xe60b;'
      },
      {
        id: guid(),
        name: 'tenant-profile.tenant-profiles',
        type: 'link',
        path: '/adminProfiles',
        icon: '&#xe60f;'
      },
      {
        id: guid(),
        name: 'widget.widget-library',
        type: 'link',
        path: '/widgets-bundles',
        icon: 'now_widgets',
        useOriginalIcon: true
      },
      // {
      //   id: guid(),
      //   name: 'admin.system-settings',
      //   type: 'toggle',
      //   path: '/settings',
      //   height: '200px',
      //   icon: 'settings',
      //   pages: [
      //     {
      //       id: guid(),
      //       name: 'admin.general',
      //       type: 'link',
      //       path: '/settings/general',
      //       icon: 'settings_applications',
      //       useOriginalIcon: true
      //     },
      //     {
      //       id: guid(),
      //       name: 'admin.outgoing-mail',
      //       type: 'link',
      //       path: '/settings/outgoing-mail',
      //       icon: 'mail',
      //       useOriginalIcon: true
      //     },

      //     {
      //       id: guid(),
      //       name: 'admin.security-settings',
      //       type: 'link',
      //       path: '/settings/security-settings',
      //       icon: 'security',
      //       useOriginalIcon: true
      //     },
      //     {
      //       id: guid(),
      //       name: 'admin.oauth2.oauth2',
      //       type: 'link',
      //       path: '/settings/oauth2',
      //       icon: 'security',
      //       useOriginalIcon: true
      //     }
      //   ]
      // }
      {
        id: guid(),
        // name: 'admin.system-settings',
        // type: 'toggle',
        // path: '/settings',
        // height: '230px',
        // icon: 'settings',
        // pages: [
        //   {
            // id: guid(),
            name: 'admin.general',
            type: 'link',
            path: '/settings/general',
            icon: 'settings_applications',
            useOriginalIcon: true
          },
          {
            id: guid(),
            name: 'admin.logo',
            type: 'link',
            path: '/settings/logo',
            icon: 'settings_applications',
            useOriginalIcon: true
          },
          {
            id: guid(),
            name: 'admin.outgoing-mail',
            type: 'link',
            path: '/settings/outgoing-mail',
            icon: 'mail',
            useOriginalIcon: true
          },

          {
            id: guid(),
            name: 'admin.security-settings',
            type: 'link',
            path: '/settings/security-settings',
            icon: 'security',
            useOriginalIcon: true
          },
          {
            id: guid(),
            name: 'admin.oauth2.oauth2',
            type: 'link',
            path: '/settings/oauth2',
            icon: 'security',
            useOriginalIcon: true
          }
        // ]
      // }
    );
    return sections;
  }

  private buildSysAdminHome(authUser: any): Array<HomeSection> {
    const homeSections: Array<HomeSection> = [];
    homeSections.push(
      {
        name: 'line-first',
        places: [
          {
            name: 'tenant.tenants',
            icon: '&#xe60b;',
            path: '/admins',
            style: {
              'background': 'linear-gradient(135deg, #27A6FF 0%, #36A5FF 100%)'
            }
          },
          {
            name: 'tenant-profile.tenant-profiles',
            icon: '&#xe60f;',
            path: '/adminProfiles',
            style: {
              'background': 'linear-gradient(135deg, #27A6FF 0%, #36A5FF 100%)'
            }
          },
          {
            name: 'widget.widget-library',
            icon: 'now_widgets',
            path: '/widgets-bundles',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #3C9DFD 0%, #3C72F6 100%)'
            }
          },
          {
            name: '文件库',
            icon: 'now_widgets',
            path: '/static-file',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #3C9DFD 0%, #3C72F6 100%)'
            }
          },
          {
            name: 'admin.general',
            icon: 'settings_applications',
            path: '/settings/general',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #2499FF 0%, #4A88FF 100%)'
            }
          }
        ]
      },
      {
        name: 'line-second',
        places: [
          {
            name: 'admin.logo',
            icon: 'settings_applications',
            path: '/settings/logo',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #2499FF 0%, #4A88FF 100%)'
            }
          },
          {
            name: 'admin.outgoing-mail',
            icon: 'mail',
            path: '/settings/outgoing-mail',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #3BA1FE 0%, #3E79F7 100%)'
            }
          },
          {
            name: 'admin.sms-provider-settings',
            icon: 'sms',
            path: '/settings/sms-provider',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #3BA1FE 0%, #3E79F7 100%)'
            }
          },
          {
            name: 'admin.security-settings',
            icon: 'security',
            path: '/settings/security-settings',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #4B8FFE 0%, #3863ED 100%)'
            }
          },
          {
            name: 'admin.oauth2.oauth2',
            icon: 'security',
            path: '/settings/oauth2',
            useOriginalIcon: true,
            style: {
              'background': 'linear-gradient(135deg, #3C7CF8 0%, #2E51EA 100%)'
            }
          }
        ]
      }
    )

    return homeSections;
  }

  private buildTenantAdminMenu(authUser: any): Array<MenuSection> {
    const sections: Array<MenuSection> = [];
    sections.push(
      {
        id: guid(),
        name: 'home.home',
        type: 'link',
        path: '/home',
        notExact: true,
        icon: '&#58890;'
      },
      {
        id: guid(),
        name: 'rulechain.rulechains',
        type: 'link',
        path: '/ruleChains',
        icon: '&#xe638;'
      },
      {
        id: guid(),
        name: 'customer.customers',
        type: 'link',
        path: '/userGroups',
        icon: '&#xe60b;'
      },
      {
        id: guid(),
        name: 'asset.assets',
        type: 'link',
        path: '/assetst',
        // path: '/assetss',
        icon: '&#xe636;'
      },
      {
        id: guid(),
        name: 'device.devices',
        type: 'link',
        path: '/devices',
        icon: '&#xe60e;'
      },
      {
        id: guid(),
        name: '智能设备',
        type: 'link',
        path: '/intelligent-device',
        icon: '&#xe661;'
      },
      {
        id: guid(),
        name: 'device-profile.device-profiles',
        type: 'link',
        path: '/deviceProfiles',
        icon: '&#xe60f;'
      },
      {
        id: guid(),
        name: 'entity-view.entity-views',
        type: 'link',
        path: '/entityViews',
        icon: '&#xe60d;'
      },

      {
        id: guid(),
        name: 'dashboard.dashboards',
        type: 'link',
        path: '/dashboards',
        icon: '&#xe613;'
      },
      {
        id: guid(),
        name: 'admin.home-settings',
        type: 'link',
        path: '/settings/home',
        icon: '&#xe60c;'
      },
      {
        id: guid(),
        name: 'audit-log.audit-logs',
        type: 'link',
        path: '/auditLogs',
        icon: '&#xe637;'
      }

    );
    return sections;
  }

  private buildTenantAdminHome(authUser: any): Array<HomeSection> {
    const homeSections: Array<HomeSection> = [];
    homeSections.push(
      {
        name: 'row-first',
        places: [
          {
            name: 'rulechain.rulechains',
            icon: '&#xe638;',
            path: '/ruleChains',
            style: {
              'background': 'linear-gradient(135deg, #27A6FF 0%, #36A5FF 100%)'
            }
          },
          {
            name: 'customer.customers',
            icon: '&#xe60b;',
            path: '/userGroups',
            style: {
              'background': 'linear-gradient(135deg, #27A6FF 0%, #36A5FF 100%)'
            }
          },
          {
            name: 'asset.assets',
            icon: '&#xe636;',
            path: '/assetst',
            // path: '/assetss',
            style: {
              'background': 'linear-gradient(135deg, #3C9DFD 0%, #3C72F6 100%)'
            }
          },
          {
            name: 'device.devices',
            icon: '&#xe60e;',
            path: '/devices',
            style: {
              'background': 'linear-gradient(135deg, #4688FE 0%, #304DEE 100%)'
            }
          }
        ]
      },
      {
        name: 'row-second',
        places: [
          {
            name: 'device-profile.device-profiles',
            icon: '&#xe60f;',
            path: '/deviceProfiles',
            style: {
              'background': 'linear-gradient(135deg, #2499FF 0%, #4A88FF 100%)'
            }
          },
          {
            name: 'entity-view.entity-views',
            icon: '&#xe60d;',
            path: '/entityViews',
            style: {
              'background': 'linear-gradient(135deg, #3BA1FE 0%, #3E79F7 100%)'
            }
          },
          {
            name: 'dashboard.dashboards',
            icon: '&#xe613;',
            path: '/dashboards',
            style: {
              'background': 'linear-gradient(135deg, #4B8FFE 0%, #3863ED 100%)'
            }
          },
          {
            name: 'audit-log.audit-logs',
            icon: '&#xe637;',
            path: '/auditLogs',
            style: {
              'background': 'linear-gradient(135deg, #3C7CF8 0%, #2E51EA 100%)'
            }
          }

        ]
      }
    )

    return homeSections;
  }

  private buildCustomerUserMenu(authUser: any): Array<MenuSection> {
    const sections: Array<MenuSection> = [];
    sections.push(
      {
        id: guid(),
        name: 'home.home',
        type: 'link',
        path: '/home',
        notExact: true,
        icon: '&#xe60a;'
      },
      {
        id: guid(),
        name: 'asset.assets',
        type: 'link',
        path: '/assetst',
        // path: '/assetss',
        icon: '&#xe636;'
      },
      {
        id: guid(),
        name: 'device.devices',
        type: 'link',
        path: '/devices',
        icon: '&#xe60e;'
      },
      {
        id: guid(),
        name: 'entity-view.entity-views',
        type: 'link',
        path: '/entityViews',
        icon: '&#xe60d;'
      },
      {
        id: guid(),
        name: 'dashboard.dashboards',
        type: 'link',
        path: '/dashboards',
        icon: '&#xe613;'
      }
    );
    return sections;
  }

  private buildCustomerUserHome(authUser: any): Array<HomeSection> {
    const homeSections: Array<HomeSection> = [];

    homeSections.push(
      {
        name: 'row-first',
        places: [
          {
            name: 'asset.assets',
            icon: '&#xe636;',
            path: '/assetst',
            // path: '/assetss',
            style: {
              'background': 'linear-gradient(135deg, #27A6FF 0%, #36A5FF 100%)'
            }
          },
          {
            name: 'device.devices',
            icon: '&#xe60e;',
            path: '/devices',
            style: {
              'background': 'linear-gradient(135deg, #27A6FF 0%, #36A5FF 100%)'
            }
          },
          {
            name: 'entity-view.entity-views',
            icon: '&#xe60d;',
            path: '/entityViews',
            style: {
              'background': 'linear-gradient(135deg, #3C9DFD 0%, #3C72F6 100%)'
            }
          },
          {
            name: 'dashboard.dashboards',
            icon: '&#xe613;',
            path: '/dashboards',
            style: {
              'background': 'linear-gradient(135deg, #4688FE 0%, #304DEE 100%)'
            }
          }
        ]
      }
    );
    return homeSections;
  }

  public menuSections(): Observable<Array<MenuSection>> {
    return this.menuSections$;
  }

  public homeSections(): Observable<Array<HomeSection>> {
    return this.homeSections$;
  }

}

