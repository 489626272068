<mat-form-field appearance="standard" [formGroup]="entityTypeListFormGroup" class="mat-block">
  <mat-chip-list #chipList formControlName="entityTypeList">
    <mat-chip
      *ngFor="let entityType of entityTypeList"
      [selectable]="!disabled"
      [removable]="!disabled"
      (removed)="remove(entityType)">
      {{entityType.name}}
      <mat-icon matChipRemove *ngIf="!disabled">close</mat-icon>
    </mat-chip>
    <input matInput type="text" placeholder="{{ !disabled ? ((!entityTypeList || !entityTypeList.length) ? placeholder : secondaryPlaceholder) : '' }}"
           style="max-width: 200px;"
           #entityTypeInput
           (focusin)="onFocus()"
           formControlName="entityType"
           matAutocompleteOrigin
           #origin="matAutocompleteOrigin"
           [matAutocompleteConnectedTo]="origin"
           [matAutocomplete]="entityTypeAutocomplete"
           [matChipInputFor]="chipList">
  </mat-chip-list>
  <mat-autocomplete #entityTypeAutocomplete="matAutocomplete"
                    class="tb-autocomplete"
                    [displayWith]="displayEntityTypeFn">
    <mat-option *ngFor="let entityType of filteredEntityTypeList | async" [value]="entityType">
      <span [innerHTML]="entityType.name | highlight:searchText"></span>
    </mat-option>
    <mat-option *ngIf="!(filteredEntityTypeList | async)?.length" [value]="null">
      <span>
          {{ translate.get('entity.no-entity-types-matching', {entityType: searchText}) | async }}
      </span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="entityTypeListFormGroup.get('entityTypeList').hasError('required')">
    {{ 'entity.entity-type-list-empty' | translate }}
  </mat-error>
</mat-form-field>
