<div class="link-bar">
    <ul class="link-bar-container">
        <li *ngFor="let item of linkData; let i = index">
            <a class="menu-title" [ngClass]="{'is-active': item.title === '物联网应用'}" *ngIf="item.type === 'link'" (click)="skip(item.path)">
                <i class="iconfont link-bar-icon" [innerHTML]="item.icon || ''"></i>
                {{ item.title }}
            </a>
            
            <a *ngIf="item.type === 'toggle'" class="menu-title show-sub-menu" (mouseenter)="onMouseEnter(i)" (mouseleave)="onMouseLeave(i)">
                <i class="iconfont link-bar-icon" [innerHTML]="item.icon || ''"></i>
                {{ item.title }}
                <i class="iconfont dropdown-icon" [@rotate]="rotateState[i] || 'normal'">&#xe658;</i>
                <tb-link-menu-selector [links]="item.items" position="bottom" [@showMenu]="state[i] || 'void'"></tb-link-menu-selector>
            </a>
        </li>
    </ul>
</div>