import { Component, OnInit } from '@angular/core';
import { MenuService } from '@core/services/menu.service';
import { MenuSection } from '@core/services/menu.models';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '**',
    redirectTo: 'home'
  }
];
@Component({
  selector: 'tb-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

   display1=false;
   display2=false;
  menuSections$ = this.menuService.menuSections();
  arr=[];
  arr1=[];
  arr2=[];
  arr3=[];

  constructor(private menuService: MenuService) {
  }

  trackByMenuSection(index: number, section: MenuSection){
    return section.id;
  }

  ngOnInit() {
    this.menuSections$.subscribe(arr =>{
     if(arr.length==9){
      this.arr = arr;
      this.arr1.push(arr[0]);
      this.arr2.push(arr[1]);
      this.arr2.push(arr[2]);
      this.arr2.push(arr[3]);
      this.arr3.push(arr[4]);
      this.arr3.push(arr[5]);
      this.arr3.push(arr[6]);
      this.arr3.push(arr[7]);
      this.arr3.push(arr[8]);
     }
    })
    
  }

  display(){
    this.display1=true;
  }
  openmenu(){
    this.display2=true;
  }
  closemenu(){
    this.display1=false;
  }
  menuclose(){
    this.display2=false;
  }
  openServerWindow() {
    window.open('/WebConfig_1', '_blank')
  }
}
