<form class="tb-node-script-test-dialog"
      [formGroup]="nodeScriptTestFormGroup" (ngSubmit)="save()">
  <mat-toolbar fxLayout="row" color="primary">
    <h2>{{ 'rulenode.test-script-function' | translate }}</h2>
    <span fxFlex></span>
    <button mat-button mat-icon-button
            (click)="cancel()"
            type="button">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </mat-toolbar>
  <div mat-dialog-content fxFlex style="position: relative;">
    <div class="tb-absolute-fill">
      <div #topPanel class="tb-split tb-split-vertical">
        <div #topLeftPanel class="tb-split tb-content">
          <div class="tb-resize-container">
            <div class="tb-editor-area-title-panel">
              <label translate>rulenode.message</label>
            </div>
            <div formGroupName="payload" fxLayout="column" style="height: 100%;">
              <div fxLayout="row">
                <tb-message-type-autocomplete
                  style="margin-bottom: 0; min-width: 300px;"
                  formControlName="msgType"
                  required>
                </tb-message-type-autocomplete>
              </div>
              <tb-json-content
                #payloadContent
                fxFlex
                formControlName="msg"
                label="{{ 'rulenode.message' | translate }}"
                [contentType]="contentTypes.JSON"
                validateContent
                [fillHeight]="true">
              </tb-json-content>
            </div>
          </div>
        </div>
        <div #topRightPanel class="tb-split tb-content">
          <div tb-toast toastTarget="metadataPanel" class="tb-resize-container">
            <div class="tb-editor-area-title-panel">
              <label translate>rulenode.metadata</label>
            </div>
            <tb-key-val-map
              formControlName="metadata"
              titleText="rulenode.metadata">
            </tb-key-val-map>
          </div>
        </div>
      </div>
      <div #bottomPanel class="tb-split tb-split-vertical">
        <div #bottomLeftPanel class="tb-split tb-content">
          <div class="tb-resize-container">
            <div class="tb-editor-area-title-panel tb-js-function">
              <label>{{ functionTitle }}</label>
            </div>
            <tb-js-func
              formControlName="script"
              functionName="{{ data.functionName }}"
              [functionArgs]="data.argNames"
              [validationArgs]="[data.msg, data.metadata, data.msgType]"
              resultType="object"
              [fillHeight]="true">
            </tb-js-func>
          </div>
        </div>
        <div #bottomRightPanel class="tb-split tb-content">
          <div class="tb-resize-container">
            <div class="tb-editor-area-title-panel">
              <label translate>rulenode.output</label>
            </div>
            <tb-json-content
              fxFlex
              formControlName="output"
              label="{{ 'rulenode.output' | translate }}"
              [contentType]="contentTypes.JSON"
              validateContent="false"
              readonly="true"
              [fillHeight]="true">
            </tb-json-content>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <button mat-button mat-raised-button color="primary"
            type="button"
            (click)="test()"
            [disabled]="(isLoading$ | async) || nodeScriptTestFormGroup.invalid">
      {{ 'rulenode.test' | translate }}
    </button>
    <span fxFlex></span>
    <button mat-button color="primary"
            type="button"
            cdkFocusInitial
            [disabled]="(isLoading$ | async)"
            (click)="cancel()">
      {{ 'action.cancel' | translate }}
    </button>
    <button mat-button mat-raised-button color="primary"
            type="submit"
            [disabled]="(isLoading$ | async) || nodeScriptTestFormGroup.get('script').invalid || !nodeScriptTestFormGroup.get('script').dirty">
      {{ 'action.save' | translate }}
    </button>
  </div>
</form>
