<div class="tb-request-password-reset-content mat-app-background tb-dark" fxLayout="row" fxLayoutAlign="center center" style="width: 100%;">
  <mat-card fxFlex="initial" class="tb-request-password-reset-card">
    <img src="../../../../../assets/login_slider.png" class="reset-password-slider">
    <mat-card-title class="layout-padding">
      <span translate class="mat-headline">login.request-password-reset</span>
    </mat-card-title>
    <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
    </mat-progress-bar>
    <span style="height: 4px;" *ngIf="!(isLoading$ | async)"></span>
    <mat-card-content>
      <form [formGroup]="requestPasswordRequest" (ngSubmit)="sendResetPasswordLink()">
        <fieldset [disabled]="isLoading$ | async">
          <div tb-toast fxLayout="column" class="layout-padding">
            <!-- <span style="height: 50px;"></span> -->
            <mat-form-field class="mat-block" hideRequiredMarker>
              <mat-label translate>login.email</mat-label>
              <input matInput type="email" autofocus formControlName="email" email required/>
              <mat-icon class="iconfont" matPrefix>&#xe645;</mat-icon>
              <mat-error *ngIf="requestPasswordRequest.get('email').invalid">
                {{ 'user.invalid-email-format' | translate }}
              </mat-error>
            </mat-form-field>
            <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="16px" fxLayoutAlign="start center"
                 fxLayoutAlign.gt-xs="center start">
              <button mat-raised-button color="primary" type="button" [disabled]="(isLoading$ | async)"
                      routerLink="/login">
                {{ 'action.cancel' | translate }}
              </button>
              <button mat-raised-button color="accent" type="submit" [disabled]="(isLoading$ | async)">
                {{ 'login.request-password-reset' | translate }}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </mat-card-content>
  </mat-card>
</div>
