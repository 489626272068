<section fxLayout="row">
  <div fxHide.lt-lg *ngIf="displayUserInfo" class="tb-user-info" fxLayout="row">
    <mat-icon class="iconfont tb-mini-avatar" style="font-size: 28px; line-height: 28px;">&#xe66c;</mat-icon>
    <div fxLayout="column" fxLayoutAlign="center">
      <span *ngIf="userDisplayName$ | async; let userDisplayName" class="tb-user-display-name">{{ userDisplayName }}</span>
      <span *ngIf="authorityName$ | async; let authorityName" class="tb-user-authority">{{ authorityName | translate }}</span>
    </div>
  </div>
  <button mat-button mat-icon-button [matMenuTriggerFor]="userMenu">
    <mat-icon class="material-icons">more_vert</mat-icon>
  </button>
  <mat-menu #userMenu="matMenu" xPosition="before" class="menu-icon">
    <div class="tb-user-menu-items" *ngIf="authority$ | async; let authority">
      <button mat-menu-item (click)="openProfile()" style="color: white;">
        <mat-icon class="iconfont" style="color: white;">&#xe66c;</mat-icon>
        <span translate style="font-size: 14px;">home.profile</span>
      </button>
      <button mat-menu-item (click)="logout()" style="color: white;">
        <mat-icon class="material-icons" style="color: white;">exit_to_app</mat-icon>
        <span translate style="font-size: 14px;">home.logout</span>
      </button>
    </div>
  </mat-menu>

  <button mat-button class="help-icon" [matMenuTriggerFor]="aboutPlatformMenu" (menuOpened)="openHelp()" (menuClosed)="closeHelp()">
    <span translate style="font-size: 16px;">help.help</span>
    <mat-icon class="material-icons" [ngClass]="{'rotate':isOpen}">keyboard_arrow_down</mat-icon>
  </button>
  <mat-menu #aboutPlatformMenu="matMenu" xPosition="before" class="menu-icon">
    <div class="tb-user-menu-items" >
      <button mat-menu-item (click)="openAboutPlatform()" style="color: white;">
        <mat-icon class="material-icons" style="color: white;">help</mat-icon>
        <span translate style="font-size: 14px;">help.about-platform</span>
      </button>
    </div>
  </mat-menu>
</section>
