<div fxLayout="row" fxLayoutGap="12px" [fxHide]="isShareLinkLocal()">
  <button mat-icon-button mat-raised-button class="mat-primary"
          shareButton="facebook"
          title="{{ shareTitle }}"
          description="{{ shareText }}"
          url="{{ shareLink }}"
          matTooltipPosition="above"
          matTooltip="{{ 'action.share-via' | translate:{provider:'Facebook'} }}">
    <mat-icon svgIcon="mdi:facebook"></mat-icon>
  </button>
  <button mat-icon-button mat-raised-button class="mat-primary"
          shareButton="twitter"
          title="{{ shareTitle }}"
          tags="{{ shareHashTags }}"
          url="{{ shareLink }}"
          matTooltipPosition="above"
          matTooltip="{{ 'action.share-via' | translate:{provider:'Twitter'} }}">
    <mat-icon svgIcon="mdi:twitter"></mat-icon>
  </button>
  <button mat-icon-button mat-raised-button class="mat-primary"
          shareButton="linkedin"
          title="{{ shareTitle }}"
          url="{{ shareLink }}"
          matTooltipPosition="above"
          matTooltip="{{ 'action.share-via' | translate:{provider:'Linkedin'} }}">
    <mat-icon svgIcon="mdi:linkedin"></mat-icon>
  </button>
  <button mat-icon-button mat-raised-button class="mat-primary"
          shareButton="reddit"
          title="{{ shareTitle }}"
          url="{{ shareLink }}"
          matTooltipPosition="above"
          matTooltip="{{ 'action.share-via' | translate:{provider:'Reddit'} }}">
    <mat-icon svgIcon="mdi:reddit"></mat-icon>
  </button>
</div>
