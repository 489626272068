import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '@core/core.state';
import { PageComponent } from '@shared/components/page.component';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Constants } from '@shared/models/constants';
import { Router } from '@angular/router';
import { OAuth2ClientInfo } from '@shared/models/oauth2.models';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'tb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends PageComponent implements OnInit {

  display = false;
  goUse = true;
  licenseDate = '';
  state = '';
  sum = 0.00 ;

  loginFormGroup = this.fb.group({
    username: '',
    password: ''
  });
  oauth2Clients: Array<OAuth2ClientInfo> = null;
  hidePassword = true;
  loginsrc: string | any = 'assets/logo_title_white.png'
  siteUrl: string | any = 'assets/default_siteicon.png'
  productName = '';
  defaultTitle = '慧安蜂巢';
  defaultProductName = '物联网操作系统平台';
  
  constructor(protected store: Store<AppState>,
              private authService: AuthService,
              public fb: FormBuilder,
              private router: Router,
              private translate: TranslateService,
              private sanitizer: DomSanitizer) {
    super(store);
  }

  ngOnInit() {
    this.productName = this.defaultProductName
    this.authService.getLogo().subscribe((res)=> {
      this.loginsrc = this.sanitizer.bypassSecurityTrustUrl(res['icon'] || 'assets/logo_title_white.png');
      this.siteUrl = this.sanitizer.bypassSecurityTrustUrl(res['siteIcon'] || 'assets/default_siteicon.png')
      document.title = res['title'] || this.defaultTitle + ' | ' + this.translate.instant('login.login')
      this.productName = res["productName"] || this.defaultProductName

      localStorage.setItem('plat_title', res["title"] || this.defaultTitle) // 平台标题
      localStorage.setItem('plat_icon', res["siteIcon"]) // 平台ICON
      localStorage.setItem('plat_logo', res["icon"]) // 平台logo
      localStorage.setItem('product_name', this.productName) // 产品名称
    })
    this.oauth2Clients = this.authService.oauth2Clients;

    if (location.href.indexOf('map') > -1) {
      this.loginFormGroup.get("username").setValue("admin")
      this.loginFormGroup.get("password").setValue("123456")
      this.login()
    }
  }

  closedialog() {
    this.display = false;
  }
  //跳转路由
  goWebconfig (){
    window.open('/WebConfig_1/#/license-service','_self')
  }
  //正常使用
  goHome() {
    if (this.loginFormGroup.valid) {
      this.authService.login(this.loginFormGroup.value).subscribe(
        () => {
          localStorage.setItem('registerName', this.loginFormGroup.value.username)
        },
        (error: HttpErrorResponse) => {
          if (error && error.error && error.error.errorCode) {
            if (error.error.errorCode === Constants.serverErrorCode.credentialsExpired) {
              this.router.navigateByUrl(`login/resetExpiredPassword?resetToken=${error.error.resetToken}`);
            }
          }
        }
      );
    } else {
      Object.keys(this.loginFormGroup.controls).forEach(field => {
        const control = this.loginFormGroup.get(field);
        control.markAsTouched({onlySelf: true});
      });
    }
  }
  login(): void {
    //license验证 登录
    this.authService.getLicense().subscribe((res)=> {
      this.display = res.isEject
      this.sum = parseFloat(res.date)
      this.state = res.state
      if( this.sum == 0.00) {
        this.licenseDate = "0"
      }else if(this.sum <= 1) {
        this.licenseDate = "1"
      }else if (this.sum <= 7){
        this.licenseDate = '7'
      }
      if( this.display == false) {
        this.goHome()
      // if (this.loginFormGroup.valid) {
      //   this.authService.login(this.loginFormGroup.value).subscribe(
      //     () => {},
      //     (error: HttpErrorResponse) => {
      //       if (error && error.error && error.error.errorCode) {
      //         if (error.error.errorCode === Constants.serverErrorCode.credentialsExpired) {
      //           this.router.navigateByUrl(`login/resetExpiredPassword?resetToken=${error.error.resetToken}`);
      //         }
      //       }
      //     }
      //   );
      // } else {
      //   Object.keys(this.loginFormGroup.controls).forEach(field => {
      //     const control = this.loginFormGroup.get(field);
      //     control.markAsTouched({onlySelf: true});
      //   });
      // }
    }
    })
  }
}

