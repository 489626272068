<section fxLayout="row" class="layout-wrap tb-footer-fab-buttons" [ngClass]="{'relative-buttons': relative}">
  <div class="fab-container">
    <button [disabled]="isLoading$ | async"
            mat-fab class="fab-toggler tb-btn-footer"
            color="accent"
            matTooltip="{{ footerFabButtons.fabTogglerName | translate }}"
            matTooltipPosition="above"
            (click)="onToggleFab()">
      <mat-icon [@fabToggler]="{value: fabTogglerState}">{{ footerFabButtons.fabTogglerIcon }}</mat-icon>
    </button>
    <div [@speedDialStagger]="buttons.length">
      <button *ngFor="let btn of buttons"
              mat-fab
              color="accent"
        matTooltip="{{ btn.name | translate }}"
        matTooltipPosition="above"
        (click)="btn.onAction($event)">
        <mat-icon>{{btn.icon}}</mat-icon>
      </button>
    </div>
  </div>
</section>
