import { Component, OnInit, Input } from '@angular/core';
import { ILink } from '@shared/models/link.models';
import { showMenuTrigger, rotateTrigger } from '@shared/animations/link-menu.animations';

@Component({
  selector: 'tb-link-menu-selector',
  templateUrl: './link-menu-selector.component.html',
  styleUrls: ['./link-menu-selector.component.scss'],
  animations: [showMenuTrigger, rotateTrigger]
})
export class LinkMenuSelectorComponent implements OnInit {

  @Input() links: Array<ILink>;
  @Input() position: string;

  state: Array<'void' | 'hover'> = [];

  rotateState: Array<'normal' | 'rotate'> = [];

  timer: NodeJS.Timeout = null;

  constructor() { }

  ngOnInit(): void {
  }

  skip(path: string) {
      history.pushState(null, null, path);
  }

  mouseEnter(index: number) {
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.state[index] = 'hover';
      this.rotateState[index] = 'rotate';
    }, 300)
  }

  mouseLeave(index: number) {
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    setTimeout(() => {
      this.state[index] = 'void';
      this.rotateState[index] = 'normal';
    }, 300)
  }
}
