<ul fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="ui-parent-Box tb-side-menu">
  <ng-container *ngIf="arr.length!=9">
    <li *ngFor="let section of menuSections$ | async; trackBy: trackByMenuSection" [ngSwitch]="section.type === 'link'">
      <tb-menu-link *ngSwitchCase="true" [section]="section"></tb-menu-link>
      <tb-menu-toggle *ngSwitchCase="false" [section]="section"></tb-menu-toggle>
    </li>
  </ng-container>
  <ng-container *ngIf="arr.length==9">
    <button mat-button class="help-icon" routerLink="{{arr1[0].path}}" style="width: 136px;">
      <span class="iconfont" style="margin-right: 5px;">&#xe667;</span>
      <span translate style="color: white; line-height: 64px; font-size: 16px;"> 首页 </span>
    </button>
    <button mat-button class="help-icon" [matMenuTriggerFor]="aboutPlatformMenu2" (menuOpened)="display()"
      (menuClosed)="closemenu()" [ngStyle]="{'border-bottom': display1 ? '5px solid #AE1E23' : ''}">
      <span class="iconfont" style="margin-right: 5px;">&#xe668;</span>
      <span translate style="color: white; line-height: 64px; font-size: 16px;"> 系统管理 <div
          [ngClass]="{'sanjiao':display1}" style="display: inline-block;margin-left: 4px;"> <span class="icon">&#xe658;</span> </div>
        </span>
    </button>
    <mat-menu #aboutPlatformMenu2="matMenu" xPosition="before" class="menu-icon">
      <div style="background:#222C37;width: 160px;">
        <button mat-menu-item  class="fonts" *ngFor="let section of arr2" routerLink="{{section.path}}">
          <span translate  style="color: #fff;font-size: 14px;">{{section.name}}</span>
        </button>
        <button mat-menu-item class="fonts" routerLink="/static-file">
          <span translate style="color: #fff;font-size: 14px;">文件库</span>
        </button>
      </div>
    </mat-menu>
    <button mat-button class="help-icon" [matMenuTriggerFor]="aboutPlatformMenu3" (menuOpened)="openmenu()"
      (menuClosed)="menuclose()" [ngStyle]="{'border-bottom': display2 ? '5px solid #AE1E23' : ''}">
      <span class="iconfont" style="margin-right: 5px;">&#xe669;</span>
      <span translate style="color: white; line-height: 64px; font-size: 16px;"> 系统配置 <div
          [ngClass]="{'sanjiao':display2}" style="display: inline-block;margin-left: 4px;"> <span class="icon">&#xe658;</span> </div>
        </span>
    </button>
    <mat-menu #aboutPlatformMenu3="matMenu" xPosition="before" class="menu-icon">
      <div style="width: 160px;">
        <button mat-menu-item   class="fonts" *ngFor="let section of arr3" routerLink="{{section.path}}">
          <span translate style="color: #fff;font-size: 14px;" >{{section.name}}</span>
        </button>
        <button mat-menu-item class="fonts" routerLink="/settings/sms-provider">
          <span translate style="color: #fff;font-size: 14px;">SMS服务商设置</span>
        </button>
        <button mat-menu-item class="fonts" (click)="openServerWindow()">
          <span translate style="color: #fff;font-size: 14px;">服务设置</span>
        </button>
      </div>
    </mat-menu>
  </ng-container>
</ul>