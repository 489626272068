import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { fromEvent, Observable } from "rxjs";
import { select, Store } from "@ngrx/store";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";

import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { User } from "@shared/models/user.model";
import { PageComponent } from "@shared/components/page.component";
import { AppState } from "@core/core.state";
import { getCurrentAuthState, selectAuthUser, selectUserDetails } from "@core/auth/auth.selectors";
import { MediaBreakpoints } from "@shared/models/constants";
import * as _screenfull from "screenfull";
import { MatSidenav } from "@angular/material/sidenav";
import { AuthState } from "@core/auth/auth.models";
import { WINDOW } from "@core/services/window.service";
import { instanceOfSearchableComponent, ISearchableComponent } from "@home/models/searchable-component.models";
import { AuthService } from "@core/auth/auth.service";
import { AdminService } from "@core/http/admin.service";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

const screenfull = _screenfull as _screenfull.Screenfull;

@Component({
  selector: "tb-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent extends PageComponent implements AfterViewInit, OnInit {
  authState: AuthState = getCurrentAuthState(this.store);

  forceFullscreen = this.authState.forceFullscreen;

  activeComponent: any;
  searchableComponent: ISearchableComponent;

  sidenavMode: "over" | "push" | "side" = "side";
  sidenavOpened = true;

  showLinkBar: boolean;
  showHead: boolean; // 是否显示头
  logo: SafeUrl = "assets/logo_title_white.png";

  @ViewChild("sidenav")
  sidenav: MatSidenav;

  @ViewChild("searchInput") searchInputField: ElementRef;

  fullscreenEnabled = screenfull.isEnabled;

  authUser$: Observable<any>;
  userDetails$: Observable<User>;
  userDetailsString: Observable<string>;

  searchEnabled = false;
  showSearch = false;
  searchText = "";
  defaultTitle = "慧安蜂巢";
  defaultProductName = "物联网操作系统平台";

  constructor(
    protected store: Store<AppState>,
    public authService: AuthService,
    private adminService: AdminService,
    @Inject(WINDOW) private window: Window,
    private sanitizer: DomSanitizer,
    public breakpointObserver: BreakpointObserver
  ) {
    super(store);
    this.showLinkBar = (window as any).__POWERED_BY_QIANKUN__;
    // 判断是否需要退出登录
    if ((window as any).logoutPartII) {
      this.authService.logout();
    }
    // 判断系统是不是从partI打开的
    if ((window as any).partI) {
      this.showHead = false;
    } else {
      this.showHead = true;
      this.showLinkBar = false;
    }

    // let href = window.location.href;
    // let len = href.length;
    // let index = href.indexOf("=");
    // if(index !== -1) {
    //   let logout = decodeURI(href.substr(index + 1, len - index));
    //   if(logout == 'true') {
    //     this.authService.logout();
    //   }
    //   else {
    //     let index = href.indexOf('?');
    //     window.history.replaceState({}, '', decodeURI(href.substring(0, index)));
    //   }
    // }
  }

  ngOnInit() {
    this.authUser$ = this.store.pipe(select(selectAuthUser));
    this.userDetails$ = this.store.pipe(select(selectUserDetails));
    this.userDetailsString = this.userDetails$.pipe(
      map((user: User) => {
        return JSON.stringify(user);
      })
    );
    this.adminService.getLogoSettings().subscribe((res) => {
      const urls = this.sanitizer.bypassSecurityTrustUrl(res["icon"]) || "assets/logo_title_white.png";
      const siteUrl = this.sanitizer.bypassSecurityTrustUrl(res["siteIcon"]) || "assets/default_siteicon.png";
      this.logo = urls;
      localStorage.setItem("icons", urls as string);
      localStorage.setItem("siteIcon", siteUrl as string);
      localStorage.setItem("plat_title", res["title"] || this.defaultTitle); // 平台标题
      localStorage.setItem("plat_icon", res["siteIcon"]); // 平台ICON
      localStorage.setItem("plat_logo", res["icon"]); // 平台logo
      localStorage.setItem("product_name", res["productName"] || this.defaultProductName); // 产品名称
    });

    const isGtSm = this.breakpointObserver.isMatched(MediaBreakpoints["gt-sm"]);
    this.sidenavMode = isGtSm ? "side" : "over";
    this.sidenavOpened = isGtSm;

    this.breakpointObserver.observe(MediaBreakpoints["gt-sm"]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.sidenavMode = "side";
        this.sidenavOpened = true;
      } else {
        this.sidenavMode = "over";
        this.sidenavOpened = false;
      }
    });

    this.authService.getSystemIpAndPort().subscribe((data) => {
      if (data.part1_port && data.part1_host) {
        window.sessionStorage.setItem("part1_addr", "http://" + data.part1_host + ":" + data.part1_port);
      }
      if (data.part2_port && data.part2_host) {
        window.sessionStorage.setItem("part2_addr", "http://" + data.part2_host + ":" + data.part2_port);
      }
    });
  }

  ngAfterViewInit() {
    fromEvent(this.searchInputField.nativeElement, "keyup")
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchTextUpdated();
        })
      )
      .subscribe();
  }

  sidenavClicked() {
    if (this.sidenavMode === "over") {
      this.sidenav.toggle();
    }
  }

  toggleFullscreen() {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  isFullscreen() {
    return screenfull.isFullscreen;
  }

  goBack() {
    this.window.history.back();
  }

  activeComponentChanged(activeComponent: any) {
    this.showSearch = false;
    this.searchText = "";
    this.activeComponent = activeComponent;
    if (this.activeComponent && instanceOfSearchableComponent(this.activeComponent)) {
      this.searchEnabled = true;
      this.searchableComponent = this.activeComponent;
    } else {
      this.searchEnabled = false;
      this.searchableComponent = null;
    }
  }

  displaySearchMode(): boolean {
    return this.searchEnabled && this.showSearch;
  }

  openSearch() {
    if (this.searchEnabled) {
      this.showSearch = true;
      setTimeout(() => {
        this.searchInputField.nativeElement.focus();
        this.searchInputField.nativeElement.setSelectionRange(0, 0);
      }, 10);
    }
  }

  closeSearch() {
    if (this.searchEnabled) {
      this.showSearch = false;
      if (this.searchText.length) {
        this.searchText = "";
        this.searchTextUpdated();
      }
    }
  }

  private searchTextUpdated() {
    if (this.searchableComponent) {
      this.searchableComponent.onSearchTextUpdated(this.searchText);
    }
  }
}
