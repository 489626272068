<!--<a mat-button class="tb-button-toggle"
        routerLinkActive="tb-active" [routerLinkActiveOptions]="{exact: true}" routerLink="{{section.path}}">
  <mat-icon *ngIf="!section.isMdiIcon && section.icon != null" class="material-icons">{{section.icon}}</mat-icon>
  <mat-icon *ngIf="section.isMdiIcon && section.icon != null" [svgIcon]="section.icon"></mat-icon>
  <span>{{section.name | translate}}</span>
  <span class=" pull-right fa fa-chevron-down tb-toggle-icon"
        [ngClass]="{'tb-toggled' : sectionActive()}"></span>
</a>-->
<ul id="docs-menu-{{section.name | nospace}}" class="ui-child-Box tb-menu-toggle-list" [ngStyle]="{height: sectionHeight()}">
  <!--<li *ngFor="let page of section.pages; trackBy: trackBySectionPages">-->
  <li *ngFor="let page of section; trackBy: trackBySectionPages">
    <tb-menu-link [section]="page"></tb-menu-link>
  </li>
</ul>
