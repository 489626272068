<mat-form-field [formGroup]="entityTypeFormGroup">
  <mat-label *ngIf="showLabel">{{ 'entity.type' | translate }}</mat-label>
  <mat-select [required]="required"
              class="tb-entity-type-select" matInput formControlName="entityType">
    <mat-option *ngFor="let type of entityTypes" [value]="type">
      {{ displayEntityTypeFn(type) }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="entityTypeFormGroup.get('entityType').hasError('required')">
    {{ 'entity.type-required' | translate }}
  </mat-error>
</mat-form-field>
