export const environment = {
  appTitle: '慧安蜂巢',
  clientTitle:'物联网操作系统平台',
  production: false,
// @ts-ignore
  tbVersion: TB_VERSION,
// @ts-ignore
  supportedLangs: SUPPORTED_LANGS,
  defaultLang: 'zh_CN'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
