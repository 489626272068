<div class="tb-container">
  <label class="tb-title" *ngIf="label">{{label}}</label>
  <ng-container #flow="flow"
                [flowConfig]="{singleFile: true, allowDuplicateUploads: true}">
    <div class="tb-image-select-container">
      <div *ngIf="showPreview" class="tb-image-preview-container">
        <div *ngIf="!safeImageUrl;else elseBlock" translate>dashboard.no-image</div>
        <ng-template #elseBlock><img class="tb-image-preview" [src]="safeImageUrl" /></ng-template>
      </div>
      <div *ngIf="showClearButton" class="tb-image-clear-container">
        <button mat-button mat-icon-button color="primary"
                type="button"
                (click)="clearImage()"
                class="tb-image-clear-btn"
                matTooltip="{{ 'action.remove' | translate }}"
                matTooltipPosition="above">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="drop-area tb-flow-drop"
           flowDrop
           [flow]="flow.flowJs">
        <label for="{{inputId}}" translate>dashboard.drop-image</label>
        <input class="file-input" flowButton type="file" [flow]="flow.flowJs" [flowAttributes]="{accept: 'image/*'}" id="{{inputId}}">
      </div>
    </div>
  </ng-container>
  <div class="tb-hint" *ngIf="maxSizeByte" translate [translateParams]="{ size: maxSizeByte | fileSize}">dashboard.maximum-upload-file-size</div>
</div>
