import { trigger, style, state, transition, animate } from '@angular/animations'; 

export const showMenuTrigger = trigger("showMenu", [
    state('hover', style({
        transform: 'scaleY(1)'
    })),
    state('*', style({
        transform: 'scaleY(1)'
    })),
    state('void', style({
        transform: 'scaleY(0)'
    })),
    transition('void => hover', animate("200ms")),
    transition('* => void', animate("200ms"))
]);

export const rotateTrigger = trigger("rotate", [
    state('normal', style({
        transform: 'rotate(0deg)'
    })),
    state('rotate', style({
        transform: 'rotate(180deg)'
    })),
    transition('normal <=> rotate', animate('200ms'))
])