<div class="tb-login-content mat-app-background tb-dark" fxFlex fxLayoutAlign="center center">
  <!-- 提示框 -->
  <div class="dialog" [ngStyle]="{'display': display ? 'block' : 'none' }">
    <div class="dialog-body">
      <div class="dialog-close" >
        <img src="../../../../../assets/close.png" class="close" (click)="closedialog()">
      </div>
      <div class="dialog-title">
        <img src="../../../../../assets/iclock.png" class="iclock">
        <div class="word">
          <span class="hint">{{ 'license.permission-expired-hint' | translate}}</span>
          <span class="date">{{'license.remainder'|translate }}{{licenseDate}}{{'license.day' | translate}}</span>
        </div>
      </div>
      <div class="dialog-content">
        <span>{{'license.permissions'|translate}}{{state}}。</span>
        <span>{{'license.activate-the-product' |translate}}</span>
      </div>
      <div class="dialog-button">
        <button (click)="goHome()" [ngStyle]="{'goUse': display ? 'block' : 'none' }">{{'license.use' | translate}}</button>
        <button (click)="goWebconfig()">{{'license.authorization' | translate}}</button>
      </div>
    </div>
  </div>
  <mat-card style="max-height: 80vh; overflow-y: visible;margin-top: 100px;">
    <img src="../../../../../assets/login_slider.png" class="login-slider">
    <mat-card-content>
      <form class="tb-login-form" [formGroup]="loginFormGroup" (ngSubmit)="login()">
        <fieldset [disabled]="isLoading$ | async" fxLayout="column">
          <div fxLayout="column" fxLayoutAlign="start start" style="padding: 0 16px;">
            <!--<tb-logo class="login-logo"></tb-logo>-->
            <img [src]="loginsrc" class="login-logo">
          </div>
          <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
          </mat-progress-bar>
          <span style="height: 4px;" *ngIf="!(isLoading$ | async)"></span>
          <div tb-toast fxLayout="column" class="layout-padding">
            <!--<span class="login-title" translate>common.platform-name-short</span>-->
            <span class="login-title">{{ productName }}</span>
            <div class="oauth-container tb-default" fxLayout="column" fxLayoutGap="16px" *ngIf="oauth2Clients?.length">
              <ng-container *ngFor="let oauth2Client of oauth2Clients">
                <a mat-raised-button class="login-with-button" href="{{ oauth2Client.url }}">
                  <mat-icon class="icon" svgIcon="{{ oauth2Client.icon }}"></mat-icon>
                  {{ 'login.login-with' | translate: {name: oauth2Client.name} }}
                </a>
              </ng-container>
              <div class="container-divider">
                <div class="line"><mat-divider></mat-divider></div>
                <div class="text mat-typography">{{ "login.or" | translate | uppercase }}</div>
                <div class="line"><mat-divider></mat-divider></div>
              </div>
            </div>
            <mat-form-field>
              <mat-label translate>login.username</mat-label>
              <input id="username-input" matInput autofocus formControlName="username"/>
              <mat-icon matPrefix class="iconfont">&#xe62a;</mat-icon>
              <!-- <mat-error *ngIf="loginFormGroup.get('username').invalid">
                {{ 'user.invalid-email-format' | translate }}
              </mat-error> -->
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>common.password</mat-label>
              <input id="password-input" matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"/>
	                   <button mat-icon-button matSuffix type="button"
                         (click)="hidePassword = !hidePassword"
                         [attr.aria-pressed]="hidePassword">
                         <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                     </button>
              <mat-icon matPrefix class="iconfont">&#xe62b;</mat-icon>
            </mat-form-field>
            <div fxLayoutAlign="start center" class="forgot-password">
              <button class="tb-reset-password" mat-button type="button" routerLink="/login/resetPasswordRequest">{{ 'login.forgot-password' | translate }}
              </button>
            </div>
            <div fxLayout="column" class="tb-action-button">
              <button mat-raised-button [disabled]="(isLoading$ | async)"
                      type="submit">{{ 'login.login' | translate }}</button>
            </div>
          </div>
        </fieldset>
      </form>
    </mat-card-content>
  </mat-card>
</div>
