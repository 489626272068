<section fxLayout="column" fxLayoutAlign="start start">
  <section fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
    <mat-form-field>
      <mat-placeholder translate>datetime.date-from</mat-placeholder>
      <mat-datetimepicker-toggle [for]="startDatePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #startDatePicker type="date" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="startDate" [matDatetimepicker]="startDatePicker" (ngModelChange)="onStartDateChange()">
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder translate>datetime.time-from</mat-placeholder>
      <mat-datetimepicker-toggle [for]="startTimePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #startTimePicker type="time" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="startDate" [matDatetimepicker]="startTimePicker" (ngModelChange)="onStartDateChange()">
    </mat-form-field>
  </section>
  <section fxLayout="row" fxLayout.xs="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
    <mat-form-field>
      <mat-placeholder translate>datetime.date-to</mat-placeholder>
      <mat-datetimepicker-toggle [for]="endDatePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #endDatePicker type="date" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="endDate" [matDatetimepicker]="endDatePicker" (ngModelChange)="onEndDateChange()">
    </mat-form-field>
    <mat-form-field>
      <mat-placeholder translate>datetime.time-to</mat-placeholder>
      <mat-datetimepicker-toggle [for]="endTimePicker" matPrefix></mat-datetimepicker-toggle>
      <mat-datetimepicker #endTimePicker type="time" openOnFocus="true"></mat-datetimepicker>
      <input matInput [disabled]="disabled" [(ngModel)]="endDate" [matDatetimepicker]="endTimePicker" (ngModelChange)="onEndDateChange()">
    </mat-form-field>
  </section>
</section>
