<mat-form-field [formGroup]="colorFormGroup">
  <mat-label>
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
    <span *ngIf="label">{{label}}</span>
  </mat-label>
  <div matPrefix class="tb-color-preview" (click)="showColorPicker()" style="margin-right: 5px;">
    <div class="tb-color-result" [ngStyle]="{background: colorFormGroup.get('color').value}"></div>
  </div>
  <input matInput formControlName="color" (mousedown)="openOnInput && showColorPicker()" [required]="required">
  <button *ngIf="colorClearButton"
          type="button"
          matSuffix mat-button mat-icon-button aria-label="Clear"
          (click)="clear()">
    <mat-icon class="material-icons">close</mat-icon>
  </button>
  <mat-error *ngIf="colorFormGroup.get('color').hasError('required')">
    {{ requiredText }}
  </mat-error>
</mat-form-field>
