import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@shared/components/dialog.component';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { AppState } from '@core/core.state';
import { AboutPlatform } from './user-menu.component';
import { UserService } from '@core/http/user.service';
import { AdminService } from '@core/http/admin.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'tb-about-platform',
  templateUrl: './about-platform.component.html',
  styleUrls: ['./about-platform.component.scss']
})
export class AboutPlatformComponent extends DialogComponent<AboutPlatformComponent, AboutPlatform> implements OnInit {

  imageUrl:SafeUrl = 'assets/logo_title_white.png';
  defaultImg: string = 'assets/logo_title_white.png';
  productName = '';
  defaultProductName = '物联网操作系统平台';
  constructor(@Inject(MAT_DIALOG_DATA) public data: AboutPlatform,
              public dialogRef: MatDialogRef<AboutPlatformComponent, AboutPlatform>,
              protected store: Store<AppState>,
              private userService: UserService,
              private adminService: AdminService,
              private sanitizer: DomSanitizer,
              protected router: Router) {
    super(store, router, dialogRef);

  }

  ngOnInit(): void {
    this.productName = this.defaultProductName
    this.adminService.getLogoSettings().subscribe(
      (res) => {
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(res["icon"]) || this.defaultImg
        this.productName = res["productName"]
      }
    )
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
