<button *ngIf="asButton" cdkOverlayOrigin #timewindowPanelOrigin="cdkOverlayOrigin" [disabled]="timewindowDisabled" type="button" mat-raised-button (click)="openEditMode()">
  <mat-icon class="material-icons">query_builder</mat-icon>
  <span>{{innerValue?.displayValue}}</span>
</button>
<section *ngIf="!asButton" cdkOverlayOrigin #timewindowPanelOrigin="cdkOverlayOrigin" class="tb-timewindow" fxLayout="row" fxLayoutAlign="start center">
    <button *ngIf="direction === 'left'" [disabled]="timewindowDisabled" mat-icon-button class="tb-mat-32" type="button" (click)="openEditMode()" matTooltip="{{ 'timewindow.edit' | translate }}" [matTooltipPosition]="'above'">
    <mat-icon class="material-icons">query_builder</mat-icon>
  </button>
    <span [fxHide]="hideLabel()" (click)="openEditMode()" matTooltip="{{ 'timewindow.edit' | translate }}" [matTooltipPosition]="'above'">
    {{innerValue?.displayValue}} <span [fxShow]="innerValue?.displayTimezoneAbbr !== ''">| <span class="timezone-abbr">{{innerValue.displayTimezoneAbbr}}</span></span>
    </span>
    <button *ngIf="direction === 'right'" [disabled]="timewindowDisabled" mat-icon-button class="tb-mat-32" type="button" (click)="openEditMode()" matTooltip="{{ 'timewindow.edit' | translate }}" [matTooltipPosition]="'above'">
    <mat-icon class="material-icons">query_builder</mat-icon>
  </button>
</section>