<div fxFlex class="tb-breadcrumb" fxLayout="row">
  <h1 fxFlex fxHide.gt-sm *ngIf="lastBreadcrumb$ | async; let breadcrumb">
    {{ breadcrumb.ignoreTranslate ? (breadcrumb.labelFunction ? breadcrumb.labelFunction() : breadcrumb.label) : (breadcrumb.label | translate) }}
  </h1>
  <span fxHide.lt-md fxLayout="row" *ngFor="let breadcrumb of breadcrumbs$ | async; trackBy: trackByBreadcrumbs; last as isLast;" [ngSwitch]="isLast">
	    <a *ngSwitchCase="false" [routerLink]="breadcrumb.link" [queryParams]="breadcrumb.queryParams">
        <mat-icon *ngIf="breadcrumb.isMdiIcon && !breadcrumb.useOriginalIcon" [svgIcon]="breadcrumb.icon">
	    	</mat-icon>
        <mat-icon *ngIf="!breadcrumb.isMdiIcon && breadcrumb.useOriginalIcon" class="material-icons">
          {{ breadcrumb.icon }}
        </mat-icon>
        <mat-icon *ngIf="!breadcrumb.isMdiIcon && !breadcrumb.useOriginalIcon" class="iconfont" [innerHTML]="breadcrumb.icon"></mat-icon>
        {{ breadcrumb.ignoreTranslate ? (breadcrumb.labelFunction ? breadcrumb.labelFunction() : breadcrumb.label) : (breadcrumb.label | translate) }}
	    </a>
	    <span *ngSwitchCase="true">
        <mat-icon *ngIf="breadcrumb.isMdiIcon && !breadcrumb.useOriginalIcon" [svgIcon]="breadcrumb.icon">
	    	</mat-icon>
	    	<mat-icon *ngIf="!breadcrumb.isMdiIcon && breadcrumb.useOriginalIcon" class="material-icons">
	    	  {{ breadcrumb.icon }}
        </mat-icon>
        <mat-icon *ngIf="!breadcrumb.isMdiIcon && !breadcrumb.useOriginalIcon" class="iconfont" [innerHTML]="breadcrumb.icon"></mat-icon>
        {{ breadcrumb.ignoreTranslate ? (breadcrumb.labelFunction ? breadcrumb.labelFunction() : breadcrumb.label) : (breadcrumb.label | translate) }}
      </span>
	    <span class="divider" [fxHide]="isLast"> > </span>
	</span>
</div>
