<div fxLayout="row" class="tb-entity-list-select" [formGroup]="entityListSelectFormGroup">
  <tb-entity-type-select
    style="min-width: 100px; padding-right: 8px;"
    *ngIf="displayEntityTypeSelect"
    [showLabel]="true"
    [required]="required"
    [useAliasEntityTypes]="useAliasEntityTypes"
    [allowedEntityTypes]="allowedEntityTypes"
    formControlName="entityType">
  </tb-entity-type-select>
  <tb-entity-list
    [ngClass]="{'tb-not-empty': this.modelValue.ids?.length > 0}"
    fxFlex
    *ngIf="modelValue.entityType"
    [required]="required"
    [entityType]="modelValue.entityType"
    formControlName="entityIds">
  </tb-entity-list>
</div>
