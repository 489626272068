import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'tb-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  constructor(
    private sanitizer: DomSanitizer) {
  }

  logo = this.sanitizer.bypassSecurityTrustUrl(localStorage.getItem("icons") || 'assets/logo_title_white.png');

  ngOnInit(): void {
  }

  // gotoThingsboard(): void {
  //   window.open('https://thingsboard.io', '_blank');
  // }

}
