<mat-form-field [formGroup]="queueFormGroup" class="mat-block">
  <mat-label>{{ 'queue.name' | translate }}</mat-label>
  <input matInput type="text" placeholder="{{ 'queue.select_name' | translate }}"
         #queueInput
         formControlName="queue"
         (focusin)="onFocus()"
         [required]="required"
         [matAutocomplete]="queueAutocomplete">
  <button *ngIf="queueFormGroup.get('queue').value && !disabled"
          type="button"
          matSuffix mat-button mat-icon-button aria-label="Clear"
          (click)="clear()">
    <mat-icon class="material-icons">close</mat-icon>
  </button>
  <mat-autocomplete
    class="tb-autocomplete"
    #queueAutocomplete="matAutocomplete"
    [displayWith]="displayQueueFn">
    <mat-option *ngFor="let queue of filteredQueues | async" [value]="queue">
      <span [innerHTML]="queue | highlight:searchText"></span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="queueFormGroup.get('queue').hasError('required')">
    {{ 'queue.name_required' | translate }}
  </mat-error>
  <mat-hint class="tb-hint" translate [fxShow]="!disabled">device-profile.select-queue-hint</mat-hint>
</mat-form-field>
