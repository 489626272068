import { Component, OnInit } from '@angular/core';
import { ILink, linkData } from '@shared/models/link.models';
import { showMenuTrigger, rotateTrigger } from '@shared/animations/link-menu.animations';

@Component({
  selector: 'tb-link-bar',
  templateUrl: './link-bar.component.html',
  styleUrls: ['./link-bar.component.scss'],
  animations: [showMenuTrigger, rotateTrigger]
})
export class LinkBarComponent implements OnInit {

  linkData: Array<ILink> = linkData;

  state: Array<'void' | 'hover'> = [];

  rotateState: Array<'normal' | 'rotate'> = [];

  timer: NodeJS.Timeout = null;

  constructor() { }

  ngOnInit(): void {
  }

  skip(path: string) {
      history.pushState(null, null, path);
  }

  onMouseEnter(index: number) {
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      this.state[index] = 'hover';
      this.rotateState[index] = 'rotate';
    }, 300)
    
  }

  onMouseLeave(index: number) {
    if(this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    setTimeout(() => {
      this.state[index] = 'void';
      this.rotateState[index] = 'normal';
    }, 300)
  }
}
