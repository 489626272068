import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from '@shared/models/user.model';
import { Authority } from '@shared/models/authority.enum';
import { select, Store } from '@ngrx/store';
import { AppState } from '@core/core.state';
import { selectAuthUser, selectUserDetails } from '@core/auth/auth.selectors';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '@core/auth/auth.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@core/http/user.service';
import { AboutPlatformComponent } from './about-platform.component';

export interface AboutPlatform {
  version: string;
  data:string;
  state:string;
}

@Component({
  selector: 'tb-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit, OnDestroy {

  @Input() displayUserInfo: boolean;

  authorities = Authority;

  isOpen:boolean = false;

  authority$ = this.store.pipe(
    select(selectAuthUser),
    map((authUser) => authUser ? authUser.authority : Authority.ANONYMOUS)
  );

  authorityName$ = this.store.pipe(
    select(selectUserDetails),
    map((user) => this.getAuthorityName(user))
  );

  userDisplayName$ = this.store.pipe(
    select(selectUserDetails),
    tap((value) => {
      console.log(value);
    }),
    map((user) => this.getUserDisplayName(user))
  );

  constructor(private store: Store<AppState>,
              private router: Router,
              private authService: AuthService,
              private dialog: MatDialog,
              private userService: UserService) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  getAuthorityName(user: User): string {
    let name = null;
    if (user) {
      const authority = user.authority;
      switch (authority) {
        case Authority.SYS_ADMIN:
          name = 'user.sys-admin';
          break;
        case Authority.TENANT_ADMIN:
          name = 'user.tenant-admin';
          break;
        case Authority.CUSTOMER_USER:
          name = 'user.customer';
          break;
      }
    }
    return name;
  }

  getUserDisplayName(user: User): string {
    let name = '';
    if (user) {
      if ((user.firstName && user.firstName.length > 0) ||
        (user.lastName && user.lastName.length > 0)) {
        if (user.lastName) {
          // if (name.length > 0) {
          //   name += ' ';
          // }
          name += user.lastName;
        }
        if (user.firstName) {
          name += user.firstName;
        }
      } else if(user.account && user.account.length > 0) {
        name = user.account;
      } else {
        name = user.email;
      }
    }
    return name;
  }

  openProfile(): void {
    this.router.navigate(['profile']);
  }

  logout(): void {
    this.authService.logout();
  }


  //打开帮助对话框，显示平台信息
  openAboutPlatform(): void {
    this.userService.getPlatformInfo().subscribe(
      (data) => {
        const currData = JSON.parse(data).version
        const currDate = JSON.parse(data).date
        const state = JSON.parse(data).state
        this.dialog.open<AboutPlatformComponent, AboutPlatform, void>(AboutPlatformComponent, {
          disableClose: true,
          panelClass: ['tb-dialog', 'tb-fullscreen-dialog'],
          data: {
            version: currData,
            data: currDate,
            state: state
          }
        }).afterClosed();
      }
    )
  }

  openHelp():void{
    this.isOpen = true;
  }
  closeHelp():void{
      this.isOpen = false;
  }
}
