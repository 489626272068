<div class="tb-js-func" style="background: #fff;" [ngClass]="{'tb-disabled': disabled, 'fill-height': fillHeight}"
     tb-fullscreen
     [fullscreen]="fullscreen" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" style="height: 40px;" class="tb-js-func-toolbar">
    <label class="tb-title no-padding">{{'function ' + (functionName ? functionName : '') + '(' + functionArgsString + ') {'}}</label>
    <span fxFlex></span>
    <button type='button' *ngIf="!disabled" mat-button class="tidy" (click)="beautifyJs()">
      {{'js-func.tidy' | translate }}
    </button>
    <fieldset style="width: initial">
      <div matTooltip="{{(fullscreen ? 'fullscreen.exit' : 'fullscreen.expand') | translate}}"
           matTooltipPosition="above"
           style="border-radius: 50%"
           (click)="fullscreen = !fullscreen">
        <button type='button' mat-button mat-icon-button class="tb-mat-32">
          <mat-icon class="material-icons">{{ fullscreen ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
        </button>
      </div>
    </fieldset>
  </div>
  <div id="tb-javascript-panel" class="tb-js-func-panel" fxLayout="column" tb-toast toastTarget="{{toastTargetId}}">
    <div #javascriptEditor id="tb-javascript-input" [ngClass]="{'fill-height': fillHeight}"></div>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" style="height: 40px;">
    <label class="tb-title no-padding">}</label>
  </div>
</div>
