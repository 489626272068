<form class="tb-material-icons-dialog" style="min-width: 600px;">
  <mat-toolbar fxLayout="row" color="primary">
    <h2>{{ 'icon.select-icon' | translate }}</h2>
    <span fxFlex></span>
    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <mat-slide-toggle [formControl]="showAllControl">
      </mat-slide-toggle>
      <label translate>icon.show-all</label>
    </section>
    <button mat-button mat-icon-button
            (click)="cancel()"
            type="button">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </mat-toolbar>
  <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
  </mat-progress-bar>
  <div style="height: 4px;" *ngIf="!(isLoading$ | async)"></div>
  <div class="tb-absolute-fill tb-icons-load" *ngIf="loadingIcons$ | async" fxLayout="column" fxLayoutAlign="center center">
    <mat-spinner color="accent" mode="indeterminate" diameter="40"></mat-spinner>
  </div>
  <div mat-dialog-content>
    <div class="mat-content mat-padding" fxLayout="column">
      <fieldset [disabled]="(isLoading$ | async)">
        <ng-template ngFor let-icon [ngForOf]="icons$ | async" let-last="last">
          <ng-container #iconButtons>
            <button *ngIf="icon === selectedIcon"
                    class="tb-select-icon-button"
                    mat-button mat-icon-button
                    mat-raised-button
                    color="primary"
                    (click)="selectIcon(icon)"
                    matTooltip="{{ icon }}"
                    matTooltipPosition="above"
                    type="button">
              <mat-icon>{{icon}}</mat-icon>
            </button>
            <button *ngIf="icon !== selectedIcon"
                    class="tb-select-icon-button"
                    mat-button mat-icon-button
                    (click)="selectIcon(icon)"
                    matTooltip="{{ icon }}"
                    matTooltipPosition="above"
                    type="button">
              <mat-icon>{{icon}}</mat-icon>
            </button>
          </ng-container>
        </ng-template>
      </fieldset>
    </div>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <span fxFlex></span>
    <button mat-button
            type="button"
            [disabled]="(isLoading$ | async)"
            (click)="cancel()">
      {{ 'action.cancel' | translate }}
    </button>
  </div>
</form>
