<mat-toolbar>
  <span translate>common.tip</span>
  <span class="spacer"></span>
  <button mat-icon-button>
    <mat-icon class="material-icons" (click)="cancel()">close</mat-icon>
  </button>
</mat-toolbar>
<div class="confirm-dialog-container">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <div mat-dialog-content [innerHTML]="data.message"></div>
  <div mat-dialog-actions fxLayoutAlign="end center">
    <button mat-button class="confirm-dialog-button confirm-dialog-button-false" [mat-dialog-close]="false">{{data.cancel}}</button>
    <button mat-button class="confirm-dialog-button confirm-dialog-button-true" [mat-dialog-close]="true" cdkFocusInitial>{{data.ok}}</button>
  </div>
</div>

