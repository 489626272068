<div class="tb-container">
  <label class="tb-title">{{ label }}</label>
  <ng-container #flow="flow" [flowConfig]="{ singleFile: true, allowDuplicateUploads: true }">
    <div class="tb-file-select-container">
      <div class="tb-file-clear-container">
        <button
          mat-button
          mat-icon-button
          type="button"
          (click)="clearFile()"
          class="tb-file-clear-btn"
          matTooltip="{{ 'action.remove' | translate }}"
          matTooltipPosition="above"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="drop-area tb-flow-drop" flowDrop [flow]="flow.flowJs">
        <label for="{{ inputId }}">{{ dropLabel }}</label>
        <input class="file-input" flowButton type="file" [flow]="flow.flowJs" [flowAttributes]="{ accept: accept }" id="{{ inputId }}" />
      </div>
    </div>
  </ng-container>
</div>
<div>
  <tb-error *ngIf="!fileName && required && requiredAsError" error="{{ noFileText | translate }}"></tb-error>
  <div *ngIf="!fileName && !requiredAsError" translate>{{ noFileText }}</div>
  <div *ngIf="fileName">{{ fileName }}</div>
</div>
