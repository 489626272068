<mat-form-field appearance="standard" [formGroup]="entitySubtypeListFormGroup" class="mat-block">
  <mat-chip-list #chipList formControlName="entitySubtypeList">
    <mat-chip
      *ngFor="let entitySubtype of entitySubtypeList"
      [selectable]="!disabled"
      [removable]="!disabled"
      (removed)="remove(entitySubtype)">
      {{entitySubtype}}
      <mat-icon matChipRemove *ngIf="!disabled">close</mat-icon>
    </mat-chip>
    <input matInput type="text" placeholder="{{ !disabled ? ((!entitySubtypeList || !entitySubtypeList.length) ? placeholder : secondaryPlaceholder) : '' }}"
           style="max-width: 200px;"
           #entitySubtypeInput
           (focusin)="onFocus()"
           formControlName="entitySubtype"
           matAutocompleteOrigin
           #origin="matAutocompleteOrigin"
           [matAutocompleteConnectedTo]="origin"
           [matAutocomplete]="entitySubtypeAutocomplete"
           [matChipInputFor]="chipList"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
           (matChipInputTokenEnd)="chipAdd($event)">
  </mat-chip-list>
  <mat-autocomplete #entitySubtypeAutocomplete="matAutocomplete"
                    class="tb-autocomplete"
                    (optionSelected)="selected($event)"
                    [displayWith]="displayEntitySubtypeFn">
    <mat-option *ngFor="let entitySubtype of filteredEntitySubtypeList | async" [value]="entitySubtype">
      <span [innerHTML]="entitySubtype | highlight:searchText"></span>
    </mat-option>
    <mat-option *ngIf="!(filteredEntitySubtypeList | async)?.length" [value]="null">
      <span>
          {{ translate.get(noSubtypesMathingText, {entitySubtype: searchText}) | async }}
      </span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="entitySubtypeListFormGroup.get('entitySubtypeList').hasError('required')">
    {{ subtypeListEmptyText | translate }}
  </mat-error>
</mat-form-field>
