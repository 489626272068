<form [formGroup]="colorPickerFormGroup" (ngSubmit)="select()">
  <div mat-dialog-content fxLayout="row" fxLayoutAlign="center">
    <span [style.background]="colorPickerFormGroup.get('color').value"
          [cpToggle]="true"
          [cpDialogDisplay]="'inline'"
          [colorPicker]="colorPickerFormGroup.get('color').value"
          (colorPickerChange)="onColorChange($event)">
    </span>
  </div>
  <div mat-dialog-actions fxLayout="row">
    <span fxFlex></span>
    <button mat-button
            type="button"
            [disabled]="(isLoading$ | async)"
            (click)="cancel()">
      {{ 'action.cancel' | translate }}
    </button>
    <button mat-button
            type="submit"
            [disabled]="(isLoading$ | async) || colorPickerFormGroup.invalid || !colorPickerFormGroup.dirty">
      {{ 'action.select' | translate }}
    </button>
  </div>
</form>
