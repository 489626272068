<div fxLayout="column" class="mat-content mat-padding">
  <mat-form-field>
    <mat-label>{{'dashboard.select-dashboard' | translate}}</mat-label>
    <mat-select matInput [(ngModel)]="dashboardId"
                (ngModelChange)="dashboardSelected(dashboardId)">
      <mat-option *ngFor="let dashboard of dashboards$ | async" [value]="dashboard.id.id">
        {{dashboard.title}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
