<mat-select fxHide.lt-lg
  [required]="required"
  [disabled]="disabled"
  [(ngModel)]="dashboardId"
  (ngModelChange)="dashboardIdChanged()">
  <mat-option *ngFor="let dashboard of dashboards$ | async" [value]="dashboard.id.id">
    {{dashboard.title | translate}}
  </mat-option>
</mat-select>
<section fxHide.gt-md class="tb-dashboard-select">
  <button mat-button mat-icon-button
          cdkOverlayOrigin #dashboardSelectPanelOrigin="cdkOverlayOrigin"
          (click)="openDashboardSelectPanel()"
          matTooltip="{{ 'dashboard.select-dashboard' | translate }}"
          [matTooltipPosition]="tooltipPosition">
    <mat-icon>dashboards</mat-icon>
  </button>
</section>
