<mat-form-field floatLabel="always" hideRequiredMarker class="mat-block">
  <mat-label></mat-label>
  <mat-select [required]="required"
              [disabled]="disabled"
              [(ngModel)]="widgetsBundle"
              matInput
              panelClass="tb-widgets-bundle-select"
              placeholder="{{ 'widget.select-widgets-bundle' | translate }}"
              (ngModelChange)="widgetsBundleChanged()">
    <mat-select-trigger>
      <div class="tb-bundle-item">
        <span>{{widgetsBundle?.title}}</span>
        <span translate class="tb-bundle-system" *ngIf="isSystem(widgetsBundle)">widgets-bundle.system</span>
      </div>
    </mat-select-trigger>
    <mat-option *ngFor="let widgetsBundle of widgetsBundles$ | async" [value]="widgetsBundle">
      <div class="tb-bundle-item">
        <span>{{widgetsBundle.title}}</span>
        <span translate class="tb-bundle-system" *ngIf="isSystem(widgetsBundle)">widgets-bundle.system</span>
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
