<form #inputForm="ngForm">
  <section fxLayout="row" fxLayoutGap="8px">
    <mat-form-field fxFlex="40" class="mat-block tb-value-type">
      <mat-label translate>value.type</mat-label>
      <mat-select [disabled]="disabled" matInput name="valueType" [(ngModel)]="valueType" (ngModelChange)="onValueTypeChanged()">
        <mat-select-trigger>
          <mat-icon svgIcon="{{ valueTypes.get(valueType).icon }}"></mat-icon>
          <span>{{ valueTypes.get(valueType).name | translate }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let valueType of valueTypeKeys" [value]="valueType">
          <mat-icon svgIcon="{{ valueTypes.get(valueTypeEnum[valueType]).icon }}"></mat-icon>
          <span>{{ valueTypes.get(valueTypeEnum[valueType]).name | translate }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="valueType === valueTypeEnum.STRING" fxFlex="60" class="mat-block">
      <mat-label translate>value.string-value</mat-label>
      <input [disabled]="disabled" matInput required name="value" #value="ngModel" [(ngModel)]="modelValue" (ngModelChange)="onValueChanged()"/>
      <mat-error *ngIf="value.hasError('required')">
        {{ (requiredText ? requiredText : 'value.string-value-required') | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="valueType === valueTypeEnum.INTEGER" fxFlex="60" class="mat-block">
      <mat-label translate>value.integer-value</mat-label>
      <input [disabled]="disabled" matInput required name="value" type="number" step="1" pattern="^-?[0-9]+$" #value="ngModel" [(ngModel)]="modelValue" (ngModelChange)="onValueChanged()"/>
      <mat-error *ngIf="value.hasError('required')">
        {{ (requiredText ? requiredText : 'value.integer-value-required') | translate }}
      </mat-error>
      <mat-error *ngIf="value.hasError('pattern')">
        {{ 'value.invalid-integer-value' | translate }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="valueType === valueTypeEnum.DOUBLE" fxFlex="60" class="mat-block">
      <mat-label translate>value.double-value</mat-label>
      <input [disabled]="disabled" matInput required name="value" type="number" step="any" #value="ngModel" [(ngModel)]="modelValue" (ngModelChange)="onValueChanged()"/>
      <mat-error *ngIf="value.hasError('required')">
        {{ (requiredText ? requiredText : 'value.double-value-required') | translate }}
      </mat-error>
    </mat-form-field>
    <div fxLayout="column" fxLayoutAlign="center" fxFlex="60" *ngIf="valueType === valueTypeEnum.BOOLEAN">
      <mat-checkbox [disabled]="disabled" name="value" #value="ngModel" [(ngModel)]="modelValue" (ngModelChange)="onValueChanged()" style="margin-bottom: 0;">
        {{ (modelValue ? 'value.true' : 'value.false') | translate }}
      </mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutAlign="center" fxFlex="60" *ngIf="valueType === valueTypeEnum.JSON" class="mat-block">
      <mat-form-field fxFlex class="mat-block">
        <mat-label translate>value.json-value</mat-label>
        <input [disabled]="disabled" matInput tb-json-to-string required name="value" #value="ngModel"
               [(ngModel)]="modelValue" (ngModelChange)="onValueChanged()"/>
        <button matSuffix mat-button mat-icon-button (click)="openEditJSONDialog($event)">
          <mat-icon>open_in_new</mat-icon>
        </button>
        <mat-error *ngIf="value.hasError('required')">
          {{ (requiredText ? requiredText : 'value.json-value-required') | translate }}
        </mat-error>
        <mat-error *ngIf="value.hasError('invalidJSON')">
          {{ 'value.json-value-invalid' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </section>
</form>
