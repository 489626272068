<div class="about-platform-container">
    <mat-toolbar color="primary">
        <h2 translate>help.about-platform</h2>
        <span fxFlex></span>
        <button mat-icon-button (click)="cancel()" type="button">
            <mat-icon class="material-icons">close</mat-icon>
        </button>
    </mat-toolbar>
    <mat-progress-bar color="warn" mode="indeterminate" *ngIf="isLoading$ | async">
    </mat-progress-bar>
    <div style="height: 4px;background: #5B6B7B;" *ngIf="!(isLoading$ | async)"></div>
    <div mat-dialog-content style="padding: 24px 32px;background: #5B6B7B;">
        <section style="display: flex; align-items: center;">
            <img [src]="imageUrl" οnerrοr="this.src=''assets/logo_title_white.png''" class="logo"/>
            <!-- <span style="margin-left: -9px;font-weight: 800;" translate>common.platform-name</span> -->
            <span style="margin-left: 10px;color:#fff;">{{ productName }}</span>
        </section>
        <span style="display: flex; margin-top: 20px;color:#fff;">{{ 'help.version' | translate }}: V{{ data.version }}</span>
        <span style="display: flex; margin-top: 20px;color:#fff;">{{ 'help.state' | translate }}: {{ data.state }}</span>
        <span style="display: flex; margin-top: 20px;color:#fff;">{{ 'help.date' | translate }}: {{ data.data }}</span>
    </div>
</div>