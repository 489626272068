<section fxLayout="column" class="tb-kv-map" [formGroup]="kvListFormGroup">
  <label translate class="tb-title no-padding">{{ titleText }}</label>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="max-height: 40px;"
       formArrayName="keyVals"
       *ngFor="let keyValControl of keyValsFormArray().controls; let $index = index">
    <mat-form-field fxFlex floatLabel="always" hideRequiredMarker class="mat-block"
                    style="max-height: 40px;">
      <mat-label></mat-label>
      <input [formControl]="keyValControl.get('key')" matInput required
             placeholder="{{ (keyPlaceholderText ? keyPlaceholderText : 'key-val.key') | translate }}"/>
    </mat-form-field>
    <mat-form-field fxFlex floatLabel="always" hideRequiredMarker class="mat-block"
                    style="max-height: 40px;">
      <mat-label></mat-label>
      <input [formControl]="keyValControl.get('value')" matInput required
             placeholder="{{ (valuePlaceholderText ? valuePlaceholderText : 'key-val.value') | translate }}"/>
    </mat-form-field>
    <button mat-button mat-icon-button color="primary"
            [fxShow]="!disabled"
            type="button"
            (click)="removeKeyVal($index)"
            [disabled]="isLoading$ | async"
            matTooltip="{{ 'key-val.remove-entry' | translate }}"
            matTooltipPosition="above">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <span [fxShow]="!keyValsFormArray().length"
        fxLayoutAlign="center center" [ngClass]="{'disabled': disabled}"
        class="no-data-found" translate>{{noDataText ? noDataText : 'key-val.no-data'}}</span>
  <div style="margin-top: 8px;">
    <button mat-button mat-raised-button color="primary"
            [fxShow]="!disabled"
            [disabled]="isLoading$ | async"
            (click)="addKeyVal()"
            type="button"
            matTooltip="{{ 'key-val.add-entry' | translate }}"
            matTooltipPosition="above">
      {{ 'action.add' | translate }}
    </button>
  </div>
</section>
