import { environment as env } from '@env/environment'

export interface ILink {
    title: string;
    path: string;
    icon?: string;
    type: 'toggle' | 'link';
    items?: Array<ILink> ;
}

export function skip(path: string) {
    if(!env.production) {
      window.location.href = 'http://127.0.0.1:8086/#/'+ path + '?' + getParamsString();
    }
    else {
      window.location.href = sessionStorage.getItem('part1_addr') + '/#/'+ path + '?' + getParamsString();
    }
}


export function getParamsString() {
    return encodeURI('token=' + window.localStorage.getItem('jwt_token') + '&part2_addr=' + sessionStorage.getItem('part2_addr'));
}


// export const linkData: Array<ILink> = [
//   {
//     title: "数据概览",
//     path: "dataShow",
//     type: 'link'
//   },
//   {
//     title: "基础管理",
//     path: "",
//     type: 'toggle',
//     items: [
//       {
//         title: "组织架构",
//         path: "org-org",
//         type: 'link'
//       },
//       {
//         title: "设备管理",
//         path: "device-device",
//         type: 'link'
//       },
//       {
//         title: "用户管理",
//         path: "user-user",
//         type: 'link'
//       },
//       {
//         title: "角色管理",
//         path: "user-role",
//         type: 'link'
//       },
//       {
//         title: "服务管理",
//         path: "server-server",
//         type: 'link'
//       },
//       {
//         title: "级联网关",
//         path: "fag-fag",
//         type: 'link'
//       }
//     ]
//   },
//   {
//     title: "业务管理",
//     path: "",
//     type: 'toggle',
//     items: [
//       {
//         title: "录像配置",
//         path: "store-store-old",
//         type: 'link'
//       },
//       {
//         title: "报警录像",
//         path: "alarm-alarm",
//         type: 'link'
//       },
//       {
//         title: "电视墙配置",
//         path: "tvwall-tvwall",
//         type: 'link'
//       }
//     ]
//   },
//   {
//     title: "运维统计",
//     path: "",
//     type: 'toggle',
//     items: [
//       {
//         title: "运行日志",
//         path: "statistics-log",
//         type: 'link'
//       },
//       {
//         title: "报警信息",
//         path: "statistics-alarm-log",
//         type: 'link'
//       }
//     ]
//   },
//   {
//     title: "扩展功能",
//     path: "",
//     type: 'toggle',
//     items: [
//       {
//         title: "一卡通应用",
//         path: "",
//         type: 'toggle',
//         items: [
//           {
//             title: "人卡管理",
//             path: "i-500001",
//             type: 'link'
//           },
//           {
//             title: "门禁管理",
//             path: "i-500002",
//             type: 'link'
//           },
//           {
//             title: "考勤管理",
//             path: "i-500003",
//             type: 'link'
//           },
//           {
//             title: "巡更管理",
//             path: "i-500004",
//             type: 'link'
//           },
//           {
//             title: "访客管理",
//             path: "i-500005",
//             type: 'link'
//           }
//         ]
//       },
//       {
//         title: "动环监控",
//         path: "i-600",
//         type: 'link'
//       },
//       {
//         title: "人脸应用",
//         path: "i-1100",
//         type: 'link'
//       },
//       {
//         title: "可视对讲",
//         path: "i-900",
//         type: 'link'
//       },
//       {
//         title: "停车场管理",
//         path: "i-800",
//         type: 'link'
//       }
//     ]
//   }
// ]

export const linkData: Array<ILink> = [
  {
    title: "数据概览",
    path: "/partI/dataShow",
    icon: '&#xe650;',
    type: 'link'
  },
  {
    title: "基础管理",
    path: "",
    type: 'toggle',
    icon: '&#xe64c;',
    items: [
      {
        title: "组织架构",
        path: "/partI/org-org",
        type: 'link'
      },
      {
        title: "设备管理",
        path: "/partI/device-device",
        type: 'link'
      },
      {
        title: "用户管理",
        path: "/partI/user-user",
        type: 'link'
      },
      {
        title: "角色管理",
        path: "/partI/user-role",
        type: 'link'
      },
      {
        title: "服务管理",
        path: "/partI/server-server",
        type: 'link'
      },
      {
        title: "级联网关",
        path: "/partI/fag-fag",
        type: 'link'
      }
    ]
  },
  {
    title: "业务管理",
    path: "",
    type: 'toggle',
    icon: '&#xe64e;',
    items: [
      {
        title: "录像配置",
        path: "/partI/store-store-old",
        type: 'link'
      },
      {
        title: "报警录像",
        path: "/partI/alarm-alarm",
        type: 'link'
      },
      {
        title: "电视墙配置",
        path: "/partI/tvwall-tvwall",
        type: 'link'
      }
    ]
  },
  {
    title: "运维统计",
    path: "",
    type: 'toggle',
    icon: "&#xe651;",
    items: [
      {
        title: "运行日志",
        path: "/partI/statistics-log",
        type: 'link'
      },
      {
        title: "报警信息",
        path: "/partI/statistics-alarm-log",
        type: 'link'
      },
      {
        title: "服务器运行状态",
        path: "/partI/home",
        type: 'link'
      }
    ]
  },
  {
    title: "扩展功能",
    path: "",
    type: 'toggle',
    icon: "&#xe64f;",
    items: [
      {
        title: "一卡通应用",
        path: "",
        type: 'toggle',
        items: [
          {
            title: "人卡管理",
            path: "/partI/i-500001",
            type: 'link'
          },
          {
            title: "门禁管理",
            path: "/partI/i-500002",
            type: 'link'
          },
          {
            title: "考勤管理",
            path: "/partI/i-500003",
            type: 'link'
          },
          {
            title: "巡更管理",
            path: "/partI/i-500004",
            type: 'link'
          },
          {
            title: "访客管理",
            path: "/partI/i-500005",
            type: 'link'
          }
        ]
      },
      {
        title: "动环监控",
        path: "/partI/i-600",
        type: 'link'
      },
      {
        title: "人脸应用",
        path: "/partI/i-1100",
        type: 'link'
      },
      {
        title: "可视对讲",
        path: "/partI/i-900",
        type: 'link'
      },
      {
        title: "停车场管理",
        path: "/partI/i-800",
        type: 'link'
      }
    ]
  },
  {
    title: "物联网应用",
    path: "/partII/home",
    type: 'link',
    icon: "&#xe651;"
  }
]