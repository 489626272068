<mat-sidenav-container>
  <!--侧边导航-->
  <!-- <mat-sidenav #sidenav class="tb-site-sidenav mat-elevation-z2"
               (click)="sidenavClicked()"
               [mode]="sidenavMode"
               [opened]="sidenavOpened && !forceFullscreen">
    <header class="tb-nav-header" [fxShow]="showHead">
      <mat-toolbar color="primary" class="tb-nav-header-toolbar">
        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
          <img [src]="logo" aria-label="logo" class="tb-logo-title"/>
        </div>
      </mat-toolbar>
    </header>
    <mat-toolbar color="primary" fxFlex="0%" class="tb-side-menu-toolbar" fxLayout="column" role="navigation">
      <tb-side-menu></tb-side-menu>
    </mat-toolbar>
  </mat-sidenav> -->
  <mat-sidenav-content style='margin-left:0px;'>
    <div fxLayout="column" role="main" style="height: 100%;">
      <mat-toolbar fxLayout="row" color="primary" class="mat-elevation-z1 tb-primary-toolbar" [fxShow]="showHead">
        <mat-sidenav #sidenav class="tb-site-sidenav mat-elevation-z2"
               (click)="sidenavClicked()"
               [mode]="sidenavMode"
               [opened]="sidenavOpened && !forceFullscreen">
          <header class="tb-nav-header" [fxShow]="showHead" style="width: 214px;">
            <mat-toolbar color="primary" class="tb-nav-header-toolbar">
              <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
                <img [src]="logo" aria-label="logo" class="tb-logo-title"/>
              </div>
            </mat-toolbar>
          </header>
          <mat-toolbar color="primary" fxFlex="0%" class="tb-side-menu-toolbar" fxLayout="column" role="navigation">
            <tb-side-menu style="overflow:hidden;"></tb-side-menu>
            <div class='ui-homeButton-box'>
              <button [fxShow]="!forceFullscreen" mat-icon-button id="main"
                  [ngClass]="{'tb-invisible': displaySearchMode()}"
                  fxHide.gt-sm (click)="sidenav.toggle()">
                <mat-icon class="material-icons">menu</mat-icon>
              </button>
              <button [fxShow]="forceFullscreen" mat-icon-button
                      [ngClass]="{'tb-invisible': displaySearchMode()}"
                      (click)="goBack()">
                <mat-icon class="material-icons">arrow_back</mat-icon>
              </button>
              <button mat-icon-button
                      [ngClass]="{'tb-invisible': !displaySearchMode()}"
                      (click)="closeSearch()">
                <mat-icon class="material-icons">arrow_back</mat-icon>
              </button>
              <!-- <tb-breadcrumb [fxShow]="!displaySearchMode()"
                  fxFlex [activeComponent]="activeComponent" class="mat-toolbar-tools">
              </tb-breadcrumb> -->
              <tb-link-bar [fxShow]="showLinkBar" fxFlex class="mat-toolbar-tools"></tb-link-bar>
              <div [fxShow]="displaySearchMode()" fxFlex fxLayout="row" class="tb-dark">
                <mat-form-field fxFlex floatLabel="always">
                  <mat-label></mat-label>
                  <input #searchInput matInput
                        [(ngModel)]="searchText"
                        placeholder="{{ 'common.enter-search' | translate }}"/>
                </mat-form-field>
              </div>
              <button [fxShow]="searchEnabled"
                      mat-icon-button (click)="openSearch()">
                <mat-icon class="material-icons">search</mat-icon>
              </button>
              <button *ngIf="fullscreenEnabled" [fxShow]="!displaySearchMode()"
                      mat-icon-button fxHide.lt-md (click)="toggleFullscreen()">
                <mat-icon class="material-icons">{{ isFullscreen() ? 'fullscreen_exit' : 'fullscreen' }}</mat-icon>
              </button>
            </div>
          </mat-toolbar>
        </mat-sidenav> 
        <tb-user-menu [displayUserInfo]="!displaySearchMode()" class='ui-homeUser-box'></tb-user-menu>
      </mat-toolbar>
      <mat-progress-bar color="warn" style="z-index: 10; margin-bottom: -4px; width: 100%;" mode="indeterminate"
                        *ngIf="isLoading$ | async">
      </mat-progress-bar>
      <!--主要内容-->
      <div fxFlex fxLayout="column" tb-toast class="tb-main-content">
        <!-- <tb-breadcrumb fxFlex [activeComponent]="activeComponent"></tb-breadcrumb>揭开了注释 -->
        <router-outlet (activate)="activeComponentChanged($event)"></router-outlet>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
