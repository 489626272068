<span>{{ entitySubtypeTitle | translate }}:&nbsp;&nbsp;</span>
<mat-form-field [formGroup]="subTypeFormGroup">
  <mat-select [fxShow]="subTypesLoaded" class="tb-entity-subtype-select" formControlName="subType">
    <mat-option *ngFor="let subType of subTypesOptions | async" [value]="subType">
      {{ displaySubTypeFn(subType) }}
    </mat-option>
  </mat-select>
  <mat-error *ngIf="subTypeFormGroup.get('subType').hasError('required')">
    {{ entitySubtypeRequiredText | translate }}
  </mat-error>
</mat-form-field>
