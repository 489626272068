<mat-form-field [formGroup]="selectTimezoneFormGroup" fxFlex class="mat-block">
  <mat-label translate>timezone.timezone</mat-label>
  <input matInput type="text" placeholder="{{ 'timezone.select-timezone' | translate }}"
         #timezoneInput
         formControlName="timezone"
         (focusin)="onFocus()"
         [required]="required"
         [matAutocomplete]="timezoneAutocomplete">
  <button *ngIf="selectTimezoneFormGroup.get('timezone').value && !disabled"
          type="button" style="margin-right: 1px"
          matSuffix mat-button mat-icon-button aria-label="Clear"
          (mousedown)="ignoreClosePanel = true"
          (click)="clear()">
    <mat-icon class="material-icons">close</mat-icon>
  </button>
  <mat-autocomplete class="tb-autocomplete"
                    #timezoneAutocomplete="matAutocomplete"
                    (closed)="onPanelClosed()"
                    (optionSelected)="ignoreClosePanel = true"
                    [displayWith]="displayTimezoneFn">
    <mat-option *ngFor="let timezone of filteredTimezones | async" [value]="timezone">
      <span [innerHTML]="displayTimezoneFn(timezone) | highlight:searchText"></span>
    </mat-option>
    <mat-option *ngIf="!(filteredTimezones | async)?.length" [value]="null">
      <span>
          {{ translate.get('timezone.no-timezones-matching', {timezone: searchText}) | async }}
      </span>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="selectTimezoneFormGroup.get('timezone').hasError('required')">
    {{ 'timezone.timezone-required' | translate }}
  </mat-error>
</mat-form-field>
