<section class="interval-section" fxLayout="row" fxFlex>
  <mat-form-field fxFlex>
    <mat-label translate>timewindow.interval</mat-label>
    <mat-select [disabled]="disabled" [(ngModel)]="modelValue" (ngModelChange)="onIntervalChange()">
      <mat-option *ngFor="let interval of intervals" [value]="interval">
        {{ timeIntervalTranslationMap.get(interval) | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</section>
