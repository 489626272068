import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {

    constructor(private readonly translate: TranslateService) {

    }

    getPaginatorIntl(): MatPaginatorIntl{
        const paginatorIntl = new MatPaginatorIntl();
        paginatorIntl.itemsPerPageLabel = this.translate.instant('paginator.items-per-page-label');
        paginatorIntl.nextPageLabel = this.translate.instant('paginator.next-page-label');
        paginatorIntl.previousPageLabel = this.translate.instant('paginator.previous-page-label');
        paginatorIntl.firstPageLabel = this.translate.instant('paginator.first-page-label')
        paginatorIntl.lastPageLabel = this.translate.instant('paginator.last-page-label')
        paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
        return paginatorIntl;
    }

    private getRangeLabel(page: number, pageSize: number, length: number): string {
        if(length == 0 || pageSize == 0) {
            return this.translate.instant('paginator.range-page-label-1', {length: length});//0到{{length}}
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return this.translate.instant('paginator.range-page-label-2', {startIndex: startIndex + 1, endIndex, length});//第{{startIndex}} - {{endIndex}}条，共{{length}}条
    }
}
